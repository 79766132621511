html,body,body div,span,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,abbr,address,cite,code,del,dfn,em,img,ins,kbd,q,samp,small,strong,sub,sup,var,b,i,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,figure,footer,header,menu,nav,section,time,mark,audio,video,details,summary{margin:0;padding:0;border:0;font-weight:normal;vertical-align:baseline;background:transparent}
article,aside,figure,footer,header,nav,section,details,summary{display:block}
object,embed{max-width:100%}
html{overflow-y:scroll}
ul{list-style:none}
blockquote,q{quotes:none}
blockquote:before,blockquote:after,q:before,q:after{content:'';content:none}
a{margin:0;padding:0;font-size:100%;vertical-align:baseline;background:transparent;text-decoration:none;&:active,&:hover {outline:0}}
del{text-decoration:line-through}
abbr[title],dfn[title]{border-bottom:1px dotted #000;cursor:help}
table{border-collapse:collapse;border-spacing:0}
th{font-weight:bold;vertical-align:bottom}
td{font-weight:normal;vertical-align:top}
hr{display:block;height:1px;border:0;border-top:1px solid #ccc;margin:1em 0;padding:0}
input,select{vertical-align:middle}

input[type="radio"]{vertical-align:text-bottom}
input[type="checkbox"]{vertical-align:bottom}
select,input,textarea{font:99% sans-serif}
table{font-size:inherit;font:100%}
small{font-size:85%}
strong{font-weight:bold}
td,td img{width:auto;height:auto;vertical-align:top}
sub,sup{font-size:75%;line-height:0;position:relative}
sup{top:-.5em}
sub{bottom:-.25em}

.clickable,label,input[type=button],input[type=submit],input[type=file],button{cursor:pointer}
button,input,select,textarea{margin:0}
button,input[type=button]{width:auto;overflow:visible}
input[type=submit],input[type=button],button{background-color:transparent}
div,textarea,table,td,th,code,samp{word-wrap:break-word;-webkit-hyphens:auto;-moz-hyphens:auto;-ms-hyphens:auto;-o-hyphens:auto;hyphens:auto}
textarea{display:inline-block;vertical-align:top}
/* img{max-width:100%;height:auto} */
textarea,input,select,button{padding:.5rem;border:1px solid}
html{box-sizing:border-box}
*,*:before,*:after{box-sizing:inherit}