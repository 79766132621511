/* --------------------------------------
   MOBILE            
-------------------------------------- */
table{
	width: 100%!important;
	overflow: hidden;
	td{padding: 5px;}
}
.content-info>ul{
	margin: 15px 0;
	padding-left: 0;
	>li{
		list-style-type: square;
		list-style-position: inside !important;
	}
}
h2,h3{margin: 15px 0;}
a{
	color:$fifth;
	&:hover{
		color:darken($second,5%);
	}
}
body{

	.contour{
		background:white;position: fixed;
		z-index: 100000;
		@media screen and (min-width:$containersmall) {
			&.bgt{top: 0;right: 0;left: 0;height: 10px;}
			&.bgb{bottom: 0;right: 0;left: 0;height: 10px;}
			&.bgr{bottom: 0;top: 0;right: 0;width: 10px;}
			&.bgl{bottom: 0;top: 0;left: 0;width: 10px;}
		}

	}
	.debug-badge{
			@include cf();
			max-width: $containerwidth;
			display: inline-block;
			padding: 5px;
			background: $second;color:white;font-size: .7rem;
		}
	.groupemenu{
		position: relative;
		&.mintop{
			position: fixed;
			top: 0;right: 0;
			left: 62px;
			background: $first;
			.submenu{
				overflow: visible;
				&:before{
					width: 62px;
					position: absolute;
					left: -62px;
					top: 0;bottom: 0;
					background: $sixth;
					content: "";
				}
			}
		}

		.menu-wrapper{
			@include cf();
			max-width: $containerwidth;
			margin: 0 auto;
		}
		.submenu{

			height: 0;
			overflow: hidden;
			position: absolute;
			right: 0;left: 0;
			display: none;
		}
		&:hover{
			.submenu{
				display: block;
				height: auto;
			}
		}
	}
	.contentarea{
		&.no-sidebar{
			.content{
				width: 100%;
				padding: 2%;
				overflow: hidden;
			}
		}
		.content{
			padding: 2%;

			@media screen and (min-width:$containermedium) {
				@include span(8 of 12);
		
			}
			@media screen and (min-width:$containersmall) and(max-width: $containermedium) {
				@include span(8 of 12);
		
			}
		}
		.right{
			background:$third;
			@media screen and (min-width:$containermedium) {
				padding: 20px 40px;
				@include span(4 of 12 last);
			}
			@media screen and (min-width:$containersmall) and(max-width: $containermedium) {
				padding: 2%;
				@include span(4 of 12 last);
			}

		}
	}

	header,footer,.bottom,.submenu,.contentarea,.slider{
		>.wrapper{
			@include cf();
			max-width: $containerwidth;
			margin: 0 auto;
		}
	}
	// -----------------------------------------------------------------------------
	// HEADER
	// -----------------------------------------------------------------------------





	header>.wrapper:first-of-type{
		@include cf();
		>div {
				@media screen and (min-width:$containersmall) and(max-width: $containermedium) {@include span(7 of 12); }
				@media screen and (min-width:$containermedium) {@include span(4 of 12);}
			&:nth-of-type(2){
				@media screen and (min-width:$containersmall) and(max-width: $containermedium) {@include span(5 of 12);}
				@media screen and (min-width:$containermedium) {@include span(5 of 12);}
			}
			&.top-right{
				@media screen and (min-width:$containersmall) and(max-width: $containermedium) {@include span(12 of 12);}
				@media screen and (min-width:$containermedium) {@include span(3 of 12);}
			}
		}

	}
	.breadcrumbs-wrapper,.slider-wrapper,.subcontent,.partners{margin: 0 auto; max-width: $containerwidth;}
	header{
		background: $first;
		position: relative;
		z-index: 100;
	.wrapper:last-of-type{
		position: relative;
	}


	}
	>.contentarea{

		/* background: $third; */
		min-height: 50px;


	}
	.subcontent{
		@include cf;
		margin-bottom:20px;	
		@media screen and (min-width:$containersmall) and(max-width: $containermedium) {
			.basleft{@include span(6 of 12); padding: 0 10px;}
			.basright{@include span(6 of 12);}
		}
		@media screen and (min-width:$containermedium) {
			.basleft{@include span(4 of 12); padding: 10px;}
			.basright{@include span(8 of 12);}
		}

	}


	.customlogo {
		text-align: center;
		img{ max-width: 280px;}
	}
	.grp-partenaires{
		display: none;	
		@media screen and (min-width:$containersmall) { display: block;	 }	
	}
	.bottom{
		display: none;	
		@media screen and (min-width:$containersmall) { display: block;	 }	
		background:url(images/footer.jpg) center center no-repeat #585954;
		min-height: 150px;

		>.wrapper{
			@include cf();
			>div{
				@media screen and (min-width:$containersmall){@include span(1 of 4);}
				@media screen and (min-width:$containermedium) { @include span(1 of 6);}
			}
			>div.footer5{
				box-sizing:border-box;	
				text-align: center;
				padding: 30px;
				display: none;
				@media screen and (min-width:$containersmall) {}
				@media screen and (min-width:$containermedium) {@include span(2 of 6 last);display: block;}

				img{max-width:70%;}
				strong{display: block;text-align: center;font-size: .8rem;}
			}

		}

	}
	footer{
		background: $sixth;
	}
}
.customlogo img{width: 100%;}
.partenaire{
	text-align: center;
	background: white;
	.wrapper{
		>img{
			display: inline-block;
			max-height: 4vw;
			padding: 1vw;
			width: auto !important;
		}
	}
}

//===============================================================================================================================
//===============================================================================================================================
// Pages Spécifiques
//===============================================================================================================================
//===============================================================================================================================
body#projet .content {
    width: 100%;
    padding:0 2%;

}

.contact{
	padding: 10px;
	@include cf;
	@media screen and (min-width:$containersmall) { 
		.contact-left{
			padding: 0 2%;
			@include span (6 of 12);
		}
		.contact-right{
			padding: 0 2%;
			@include span (6 of 12);
		}
	}
	

}

.bg_footer{
	display: block;	
	z-index: -1; 
	position: relative;
	margin-top: -250px;
	 .wrapper{
	 	margin: 0 auto;
	 	max-width: $containerwidth+200;
		background: url(images/logofooter.png) no-repeat transparent;
		background-position: center left;
		background-size: 250px 250px; 
		
	 height: 250px;	
	}
}
