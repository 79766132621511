
/* ------------------------------------------
   MODULES
------------------------------------------ */

#copyright {
    text-align: center;
    color: white;
    font-size: .8rem;
    padding: .2rem;
    a {
        color: $second;
        font-size: .7rem;
        padding: .2rem;
        &:hover{
            text-decoration: underline;
            color:lighten($second, 20%);
        }
    }
}

.slogan {
    font-size: 1.6rem;
    text-align: center;
    @media screen and (min-width:$containersmall){
      text-align: left;
      margin-top: 95px;
      margin-bottom: 20px;
    }
}
body:not(#projet){

    .slogan{
         display: none;
         @media screen and (min-width:$containersmall) { display: block;     }
    }
   
}
.part2{position: relative; background: $third; margin-top: 40px;    margin-bottom: 40px;    
&:before{
        @media screen and (min-width:$containermedium) { 
            background-size: contain;
            content:"";
            position: absolute; 
            width: 50%; 
            height: 450px;  
            display: inline-block;  
                background: url(images/modelisation.png) no-repeat $third;
            background-position: center right;
            }
        }

}
body .contentarea .customprojetcadomius {
    
        @media screen and (min-width:$containermedium) { max-width: $containerwidth; margin: 0 auto; }
  
    margin: 35px 0;
    min-height: 370px;
    @media screen and (min-width:$containermedium) {

    }

    /* background-size: 775px 380px; */
    &:after {
        content: "";
        display: table;
        clear: both;
    }

    .right {
        width: 100%;
        background: $third;
        @media screen and (min-width:$containermedium) { 
            width: 50% ;
            padding: 0 1% 1%;
            background: transparent;
        }        
        text-align: center;
        margin-top: 20px;
        float: right;
        text-align: center;
        h1 {
            margin-top: -40px;
            text-align: center;
            font-size: 2rem;
            color: #9B0F05;

        }
        p{
                text-align: center;
                padding: 10px;
                font-size: .9rem;
        }
        .ensavoirplus {
            margin: 30px auto;
        }
    }

    .menupicto {
        &:after {
            content: "";
            display: table;
            clear: both;
        }
        li {
            height: 100px;
            vertical-align: top;
            display: inline-block;
            width: 26%;
            margin: 0 2%;
            border-radius: 4px;
            &:nth-of-type(1) {
                background: #00AF96;
            }
            &:nth-of-type(2) {
                background: #7DAF23;
            }
            &:nth-of-type(3) {
                background: #F09100;
            }
            &:hover {
                background: #5A5A55;
            }
            a {
                color: white;
                i[class^="cadomus-ico-"] {
                    height: 75px;
                    font-size: 60px;
                    line-height: 80px;
                    display: block;
                }
                i.cadomus-ico-contrat {
                    font-size: 90px;
                }
                .kreon-picto-txt {
                    font-size: .6rem;
                    text-transform: uppercase;
                }
            }
        }
    }
}

.ensavoirplus a {
    text-transform: uppercase;
    display: inline-block;
    color: white;
    padding: 10px;
    background: #5A5A55;
    font-size: .8rem;
    &:hover {
        background: #9B0F05;
    }
}





.subcontent{

    .custom.suivez{
        display: none;
        @media screen and (min-width:$containermedium) {display: block;}
        padding-bottom: 43px;

        text-align: center;
        .suivezcadomus{
            padding-top: 20px;
            font-size: 2.2rem;
            margin-bottom: 20px;
            color:$first;

        }
        ul{
            li{display: inline-block;}
            a{
                color:$sixth;
                display: block;
                font-size: 2rem;
                &:hover{
                    color:$first;
                }
            }

        }
    }
    .nav.menu{
        text-transform: uppercase;
        li{

            position: relative;
            background: $first;
                            border-bottom: 7px solid white;
                color:white;
            &:hover{
                background: $sixth;
            }   
            &:before{
                display: inline-block;
                padding: 4px 16px;
                font-size: 3rem;
                font-family: 'cadomus' !important;
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;

                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                color:white;
            }
            a{
                padding-top: 23px;
                padding-left: 8px;
                position: absolute;
                right: 0;
                left: 80px;bottom: 0;top: 0;            
                color:white;
                font-size: .8rem;
                &:hover{
                    background: $sixth;

                }


            }
            &:nth-of-type(1){
                &:before{
                    content:"\e90b";
                }
            }
            &:nth-of-type(2){
                &:before{
                    content:"\e904";
                }
            }
            &:nth-of-type(3){
                &:before{
                    content:"\e90c";
                }
            }
            &:nth-of-type(4){
                &:before{
                    content:"\e90a";
                }
            }
        }.basleft{@include span(4 of 12); padding: 10px;}
            .basright{@include span(8 of 12);}
    }
}
/* -------------------------------------------------
   SOUTENEZ + SOCIAL
------------------------------------------------- */

.custom.social {
    padding-top: 10px;

}
.custom.social ul{
    position: absolute;
    right: 10px;
    top: 10px;
    @media screen and (min-width:$containermedium) {
        position: static;
    }

}
.custom.social ul > li {
    display: inline-block;
}

.custom.social ul > li [class^="cadomus-ico-"] {
    color: white;
    font-size: 1.3rem;
}


a.soutenez {
    @include cf;
    margin-top: 10px;
    width: 100%;
    color: $first;
    background: $second;
    border-radius: 7px;
    display: none;

    @media screen and (min-width:$containersmall) {display: block;}
    @media screen and (min-width:$containersmall) and(max-width: $containermedium) { 
        margin-top: 0;
        position: absolute;
        top: 10px;
        right:100px;
        width: 250px;

    }
}

a.soutenez .soutenez-txt {
    display: block;
    float: left;
    text-transform: uppercase;
    font-family: 'Kreon', serif;
    padding: 20px 5px;
   font-size: 1.3rem;
    @media screen and (min-width:$containermedium) and(max-width: 1200px) { font-size: 1.3vw; }
    @media screen and (min-width:$containersmall) and(max-width: $containermedium) { 
        font-size: 1rem;
        padding: 5px;
    }
}

a.soutenez .cadomus-ico-contrat {
    padding: 0 10px;
    display: block;
    float: left;
    font-size: 60px;
    @media screen and (min-width:$containersmall) and(max-width: $containermedium) { 
        font-size: 30px;
    }
}

a.soutenez:hover {
    background: white;
}


/* --------------------------------------
   SOCIAL
-------------------------------------- */

.social.partage {
    width: 16.66667%;
    float: left;
    padding-top: 8px;
    padding-bottom: 12px;
    border-bottom: 1px solid #F09100;
    color: #F09100;
}
/* ------------------------------------------
   Breadcrumb
------------------------------------------ */
 #breadcrumbs{
  font-size: .9rem;
  >span{
    display: inline-block;
    padding: 8px 0;
  }
  a{
    color:black;
    text-decoration: underline;
  }
 }

/* ------------------------------------------
   Search
------------------------------------------ */
#searchform{
    background: darken($third,3%); border-bottom: 1px solid lightgrey;

        @media screen and (min-width: $containermedium) { 
            background: white;

        }

    font-size: 0;
    >div{
        overflow: hidden;
        @include cf;
        height: 36px;   
        background: white;
        position: relative; 
        &:after{
              /* use !important to prevent issues with browser extensions that change fonts */
            font-family: 'cadomus' !important;
            content: "\e90d";  
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            color:black;
            display: inline-block;
            
            font-size: 1.5rem;
            line-height: 2rem;
            text-align: center;
            width: 32px;
            vertical-align: bottom;
            position: absolute; 
            left: 0;    bottom: 0;  top: 0; 
        }
    }
    input{
        
        border:none;
        font-size: 1rem;
        &[type="text"]{
            min-width: 400px;
           display: block;  
            padding-left: 40px; 
            position: absolute; 
            bottom: 0;  top: 0; right: 0; left: 0;  
            &:focus{
                background: $third;
            }
        }
        &[type="submit"]{
            display: none;
            float: right;
            margin-bottom: -1px;
            &:hover{
                background: $sixth;
                color:white;
            }
        }

    }
}


/* contact */
.contact{
   
    background: url(images/logofooter.png) no-repeat transparent;
     background-position: 90% 90%;
    p{
        line-height: 30px;
    }

}
.contact-right{
    background: white;
}
.contact-left>div[role="form"]{
background-color:#fafafa;border: 1px solid #eeeeee;
padding: 2%;
input,textarea{
        border: 1px solid #ddd;

 
            color: #555555;
    font-size: 14px;
    line-height: 20px !important;

    visibility: visible;
    opacity: 1;
    &[type="submit"]{
        background: $second;
        color:white;
        &:hover{
            background: $first;
        }
    }
}
}

.miniwatermark{
        right:3vw;
    bottom:3vw;
    height: 8vw;
    width: 8vw; 
        @media screen and (min-width:$containersmall) and(max-width: $containermedium) { 
            right:2vw;
    bottom:2vw;
    height: 6vw;
    width: 6vw; 
     }
         @media screen and (min-width:$containermedium) { 
            right:1vw;
            bottom:1vw;
            height: 4vw;
            width: 4vw; 
          }
    position:absolute;

    background-position:center center; 
    background-size: contain; 
    background-repeat:no-repeat; 
    background-image:url(images/watermark.png); 
}