.list-categorie{
	>div{
		border-bottom: $second solid 1px;
		position: relative;
		padding: 10px 0;

		>a,>div{
			@include cf;
			display: block;
			.thumb{

				min-width: 150px;
				min-height: 150px;
				background-size: contain;
				background-repeat: no-repeat;
				@media screen and (min-width:$containermedium) { background-position: center;}
				
				

				@media screen and (min-width: $containermedium) {@include span(2 of 10);}
			}
			.content-info{
				
				@media screen and (min-width: $containermedium) {@include span(7 of 10);}
			}
			&:hover{
				background:$third;
/* 				.thumb,.thumb.default, .lesaviezvous .thumb{
	background-color: black;
} */
			}
			&.part-scien{
				border-right: $first 10px solid;
			}
			&.part-insti{
				border-right: $second 10px solid;
			}
			&.part-prive{
				border-right: $third 10px solid;
			}
		}
	}
	span.date{
		position: absolute;
		@media screen and (min-width:$containermedium) {bottom: 0px;top: auto; }
		left: 0;
		top: 20px;
		background: $first;
		color:white;
		font-weight: bold;
		padding: 0px 5px;
		border-bottom-right-radius: 8px;
		border-top-right-radius: 8px;
	}
	.content-info{
		[class*="-posttitle"]{color:$first;padding: 8px 0;}
		padding-left: 20px;
		h2{
			margin: 0;
			line-height: 1.5rem;
		}
		.excerpt{
			color: $sixth;
			padding: 10px 0;
			>p{
				margin: 0;
			}
		}

	}
	.post-categories{
		text-align: right;
		font-size: .8rem;
		li{
			padding: 0 5px;
			display: inline-block;
			border-right: 1px solid lightgrey; 
			&:last-of-type{border-right: none;}
			a{color:$second;}
		}
	}
}

.nocontent{
	background:$sixth; 
	padding: 50px 0;
	h1{
		padding: 20px;
		color:$second;
		
	}
	text-align: center;
	img{width: 80%;margin: 0 auto;}
}


/* .thumb,.thumb.default, .lesaviezvous .thumb{
	background-color: $sixth;
	
	@media screen and (min-width:$containermedium) {; }
}
 */
.list-categorie > div.sousgalerie{

 	padding-left: 20px;	
 	border-left: 8px solid lightgrey;
 }


.post_content.parent#post-263{
	display: none;
}