/* ------------------------------------------
   RIGHT
------------------------------------------ */

.right{
  margin-top: 40px; 
  margin-bottom: 20px;  
  .picto-haut{
    bottom: 35px; 
    	@media screen and (min-width:$containermedium) {bottom: 52px;  }
    height: 70px;
    overflow: hidden;
    position: relative; 
    text-align: center; 
    [class^="cadomus-ico-"]{
        position: absolute; 
        top: 0; 
        right: 0; left: 0;  

    }
    .cadomus-ico-fond{
    	font-size: 0;
    	margin-top: -31px;z-index: 0; color: $first; 
            &:before,&:after{font-size: 8rem;}    
    	 }
    .cadomus-ico-livre{
    	font-size: 0;z-index: 10;color:white; 
        margin-top: 11px; 
        &:before,&:after{font-size: 3rem;} 

    }
    .cadomus-ico-caen{font-size:0;z-index: 10;color:white; 
        margin-top: 9px; 
		&:before,&:after{font-size: 3rem;} 
    }
  }
}

/* ------------------------------------------
   ARTICLES CONNEXES
------------------------------------------ */

.articles-connexes{
	h2{
		margin-top: -25px;
		margin-bottom: 20px;
		text-align: center;
		font-size: 1.2rem;
		@media screen and (min-width:$containersmall) { }
			@media screen and(min-width: $containermedium) { 
				font-size: 2rem;margin-top: -50px; 
			}	
	}
	h3{
		font-size: 1rem;
		text-align:center;
		margin-bottom: 15px;
		@media screen and (min-width: $containermedium) { 
			text-align: left;
			font-size: 1rem
		}	
	}
	text-align: center;
	@media screen and (min-width:$containermedium) { text-align: left;}
	

}
.right .articles-connexes img{
		@media screen and (max-width:$containermedium) { 	max-width: 60% !important; }



}

.twitter-header{
	@extend .no-mobile;
	background: $first;
	h2{
		padding: 10px;	
		
		font-size: 2rem;
		vertical-align: middle;	
		color:white;
		&:before{
			display: inline-block;	
			content: "\e900";
   			font-family: 'cadomus' !important;
   			speak: none;
   			font-style: normal;
   			font-weight: normal;
   			font-variant: normal;
   			text-transform: none;
   			line-height: 1;
		}
	}
}
.widget-easy-twitter-feed-widget-kamn{
	 @extend .no-mobile;
}