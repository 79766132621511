.loader-wrapper {

}

.loader {
  position: absolute; 
  top: 0; right: 0; left: 0;  bottom: 0; 
  background: $first;
  background-image: url(images/preloader.gif)!important ;
  background-position: 50% 50%;
  background-repeat:no-repeat;
}

