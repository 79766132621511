
$first: #9B0F05;
$second: #F09100;
$third: #F0F0DC;
$fourth:#00AF96;
$fifth:	#7DAF23;
$sixth: #5A5A55;

$black: #6D6D6D;

$containerwidth: 1200px;
$containermedium: 970px;
$containersmall:500px;

.no-mobile{
	display: none;	
	@media screen and (min-width:$containersmall) { display: block;	 }	
}

