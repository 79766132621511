// Susy
// ====
@import 'susy/language/susy';
$nogutter: ( debug:(color:rgba(red, 0.6)), columns: 12, gutter-override: no-gutters, global-box-sizing: border-box, gutter-position: inside, );
$smallgutter: ( columns: 12, gutter:2%, gutter-position: inside, );
$modulegutter: ( columns: 12, gutter:20px, gutter-position: inside, );

@mixin cf() {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}
