/* Slider */

.slick-slider {
    position: relative;
    display: block;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}

/* --------------------------------------
   CUSTOM            
-------------------------------------- */
/* .slider{
    .toutarticles{

        .cadomus-ico-livre{
            display: block;
            font-size: 4rem;
        }
        .kreon-txt{
            display: block;
            font-size: .3rem;
        }
        &:hover{
            background: $sixth;
        }
    }

>.wrapper {
    position: relative;
    .toutarticles{
        position: absolute;
        right:30px;bottom: 20px;
        background: $first;
        display: inline-block;
        text-align: center;
        max-width: 130px;
        padding: 12px 25px;
        border-radius: 7px;
        color:white;
    }
        figure{
            position: relative;
        }
        figcaption{
            box-sizing:border-box;
            background: rgba(255,255,255,.6);
            max-width: 50%;
            min-height: 200px;
            padding-top:20px;
            padding-left: 20px;
            position: absolute;
            bottom: 0;
            left: 0;
            .kreon-title{
                display: block;
            }
        }
        .introslide{
            max-width: 80%;
            float: left;
            font-size: .8rem;
            padding-top: 20px;
            padding-right: 10px;
        }
        .ensavoirplus{
                        max-width:20%;
            float: left;
         margin-top: 10px;
         a{display: block;}
        }
        .cck_module_list>div{
            position: relative;
        }
    }
   
} */

/* --------------------------------------
   SLIDER
-------------------------------------- */
.slick-arrow{
    color:white;
    cursor:pointer;
    position: absolute;
    right: 0px;
    background: rgba(255,255,255,.6);
    top: 0;
    z-index: 1;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height:40px;
    font-size: 40px;
    &:hover{
    background: $second;    
    }
    &.prev{
        right: 80px;
    }
    &.next{
        right: 40px;
    }
}

.slick-dots{
    position: absolute;
    bottom: 10px;
    left: 0;
    li{
        margin-left: 20px;
        display: inline-block;  
            background: lightgrey;
            border-radius: 500px;
            height: 9px;
            width: 9px;
            &.slick-active{
                background: $first;
            }

        button{
            background: transparent;
            border:none;
            font-size: 0;
        }

    }

}


.slick{
    .slick-track{
        figure{
            position: relative;
        }
        figcaption{
            overflow: hidden;
            box-sizing:border-box;
            background: rgba(255,255,255,.6);
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 2% 2% 30px 2% ;
            @media screen and (min-width:$containermedium) {
                width: 50%;
                width: 50%;
                min-height: 200px;
                min-width: 50%;
                padding-top:20px;
                padding-left: 20px;
                padding-right: 0;
                padding-bottom: 0;
            }
        }
        a.kreon-title{

            color:$first;
            font-size: 5vw;
            line-height: 5vw;
            @media screen and (min-width:$containermedium) {
                font-size: 3rem;
                line-height: 2.9rem;
            }
            &:hover{
                text-decoration: underline;
            }
        }
        .introslide{
            display: none;
            @media screen and (min-width:$containersmall) {
                 @include span(9 of 12);
                 display: block;
            }
            font-size: .8rem;
            padding: 10px 20px  10px 0;
        }
        .ensavoirplus{
            text-align: right;
            display: none;
            @media screen and (min-width:$containersmall) {
                @include span(3 of 12 last);display: block;
            }

            margin-top: 10px;
        }
        .cck_module_list>div{
            position: relative;
        }
    }

    .toutarticles{
        position: absolute;
        right:30px;bottom: 20px;
        background: $first;
        display: inline-block;
        text-align: center;
        max-width: 130px;
        padding: 12px 25px;
        border-radius: 7px;
        color:white;
        .cadomus-ico-livre{
            display: block;
            font-size: 4rem;
        }
        .kreon-txt{
            display: block;
            font-size: .3rem;
        }
        &:hover{
            background: $sixth;
        }
    }

}

