@charset "UTF-8";
/*
Theme Name: Cadomus
Theme URI: http://www.pixelea.fr/
Description: Thème Cadomus, Pixelea
Version: 1.0
Author: GEHIN Nicolas
 
Creation Theme by GEHIN Nicolas - Pixelea || http://www.pixelea.fr
 
*/
@import url(https://fonts.googleapis.com/css?family=Kreon:700,400|Open+Sans:400,700,400italic);
html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, menu, nav, section, time, mark, audio, video, details, summary {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent;
}

article, aside, figure, footer, header, nav, section, details, summary {
  display: block;
}

object, embed {
  max-width: 100%;
}

html {
  overflow-y: scroll;
}

ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
}

a:active, a:hover {
  outline: 0;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

th {
  font-weight: bold;
  vertical-align: bottom;
}

td {
  font-weight: normal;
  vertical-align: top;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

input[type="radio"] {
  vertical-align: text-bottom;
}

input[type="checkbox"] {
  vertical-align: bottom;
}

select, input, textarea {
  font: 99% sans-serif;
}

table {
  font-size: inherit;
  font: 100%;
}

small {
  font-size: 85%;
}

strong {
  font-weight: bold;
}

td, td img {
  width: auto;
  height: auto;
  vertical-align: top;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

.clickable, label, input[type=button], input[type=submit], input[type=file], button {
  cursor: pointer;
}

button, input, select, textarea {
  margin: 0;
}

button, input[type=button] {
  width: auto;
  overflow: visible;
}

input[type=submit], input[type=button], button {
  background-color: transparent;
}

div, textarea, table, td, th, code, samp {
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  -o-hyphens: auto;
  hyphens: auto;
}

textarea {
  display: inline-block;
  vertical-align: top;
}

/* img{max-width:100%;height:auto} */
textarea, input, select, button {
  padding: .5rem;
  border: 1px solid;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

.no-mobile, .twitter-header, .widget-easy-twitter-feed-widget-kamn {
  display: none;
}

@media screen and (min-width: 500px) {
  .no-mobile, .twitter-header, .widget-easy-twitter-feed-widget-kamn {
    display: block;
  }
}

body {
  font-family: 'Open Sans', sans-serif;
}

h1,
h2,
h3,
h4,
.nav a,
.slogan,
.footer5 strong,
[class^="kreon-"] {
  font-family: 'Kreon', serif;
  text-decoration: none;
  letter-spacing: 1px;
  text-align: left;
}

.bottom .nav a,
.right h1,
.partners h3,
[class^="opensans"] {
  font-family: 'Open Sans', sans-serif;
}

p {
  text-align: justify;
  margin: 10px 0;
}

.excerpt {
  font-size: .9rem;
}

.content-info {
  text-align: justify;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

.slogan,
.footer5 {
  color: white;
}

.slogan strong,
.footer5 strong {
  color: #F09100;
}

.nav a {
  color: #F09100;
}

.titre > h1 {
  font-size: 3rem;
  color: #9B0F05;
}

hr {
  border: 1px solid #F09100;
}

h1, h2, h3, h4, h5 {
  color: #9B0F05;
}

h3.soustitre {
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  padding: 0;
  color: #F09100;
  font-size: .8rem;
  margin-top: -13px;
}

@font-face {
  font-family: 'cadomus';
  src: url("fonts/cadomus.eot?8mwmgp");
  src: url("fonts/cadomus.eot?8mwmgp#iefix") format("embedded-opentype"), url("fonts/cadomus.ttf?8mwmgp") format("truetype"), url("fonts/cadomus.woff?8mwmgp") format("woff"), url("fonts/cadomus.svg?8mwmgp#cadomus") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="cadomus-ico-"], [class*=" cadomus-ico-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'cadomus' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cadomus-ico-twitter:before {
  content: "\e900";
}

.cadomus-ico-googleplus:before {
  content: "\e90e";
}

.cadomus-ico-caen:before {
  content: "\e901";
}

.cadomus-ico-contrat:before {
  content: "\e902";
}

.cadomus-ico-cube:before {
  content: "\e903";
}

.cadomus-ico-dialog:before {
  content: "\e904";
}

.cadomus-ico-facebook:before {
  content: "\e905";
}

.cadomus-ico-fond:before {
  content: "\e906";
}

.cadomus-ico-livre:before {
  content: "\e907";
}

.cadomus-ico-loader:before {
  content: "\e908";
}

.cadomus-ico-map:before {
  content: "\e909";
}

.cadomus-ico-photo:before {
  content: "\e90a";
}

.cadomus-ico-presse:before {
  content: "\e90b";
}

.cadomus-ico-question:before {
  content: "\e90c";
}

.cadomus-ico-search:before {
  content: "\e90d";
}

.cadomus-ico-arrow-left:before {
  content: "\e90f";
}

.cadomus-ico-arrow-left-alt1:before {
  content: "\e910";
}

.cadomus-ico-arrow-right:before {
  content: "\e911";
}

.cadomus-ico-arrow-right-alt1:before {
  content: "\e912";
}

.cadomus-ico-chevron-left2:before {
  content: "\f0a4";
}

.cadomus-ico-chevron-right2:before {
  content: "\f078";
}

.cadomus-ico-device-camera:before {
  content: "\f056";
}

.cadomus-ico-device-camera-video:before {
  content: "\f057";
}

.cadomus-ico-device-desktop:before {
  content: "\f27c";
}

.cadomus-ico-device-mobile:before {
  content: "\f038";
}

.cadomus-ico-three-bars:before {
  content: "\f05e";
}

.cadomus-ico-triangle-left:before {
  content: "\f044";
}

.cadomus-ico-triangle-right:before {
  content: "\f05a";
}

.cadomus-ico-x:before {
  content: "\f081";
}

.cadomus-ico-step-backward:before {
  content: "\f048";
}

.cadomus-ico-chevron-left:before {
  content: "\f053";
}

.cadomus-ico-chevron-right:before {
  content: "\f054";
}

.cadomus-ico-minus:before {
  content: "\f068";
}

.cadomus-ico-skip_next:before {
  content: "\e044";
}

.cadomus-ico-skip_previous:before {
  content: "\e045";
}

.cadomus-ico-call:before {
  content: "\e0b0";
}

.cadomus-ico-keyboard_arrow_left:before {
  content: "\e314";
}

.cadomus-ico-keyboard_arrow_right:before {
  content: "\e315";
}

.cadomus-ico-phone_android:before {
  content: "\e324";
}

.cadomus-ico-smartphone:before {
  content: "\e32c";
}

.cadomus-ico-dehaze:before {
  content: "\e3c7";
}

.cadomus-ico-menu:before {
  content: "\e5d2";
}

/* --------------------------------------
   MOBILE            
-------------------------------------- */
table {
  width: 100% !important;
  overflow: hidden;
}

table td {
  padding: 5px;
}

.content-info > ul {
  margin: 15px 0;
  padding-left: 0;
}

.content-info > ul > li {
  list-style-type: square;
  list-style-position: inside !important;
}

h2, h3 {
  margin: 15px 0;
}

a {
  color: #7DAF23;
}

a:hover {
  color: #d78200;
}

body .contour {
  background: white;
  position: fixed;
  z-index: 100000;
}

@media screen and (min-width: 500px) {
  body .contour.bgt {
    top: 0;
    right: 0;
    left: 0;
    height: 10px;
  }
  body .contour.bgb {
    bottom: 0;
    right: 0;
    left: 0;
    height: 10px;
  }
  body .contour.bgr {
    bottom: 0;
    top: 0;
    right: 0;
    width: 10px;
  }
  body .contour.bgl {
    bottom: 0;
    top: 0;
    left: 0;
    width: 10px;
  }
}

body .debug-badge {
  max-width: 1200px;
  display: inline-block;
  padding: 5px;
  background: #F09100;
  color: white;
  font-size: .7rem;
}

body .debug-badge:after {
  content: "";
  display: table;
  clear: both;
}

body .groupemenu {
  position: relative;
}

body .groupemenu.mintop {
  position: fixed;
  top: 0;
  right: 0;
  left: 62px;
  background: #9B0F05;
}

body .groupemenu.mintop .submenu {
  overflow: visible;
}

body .groupemenu.mintop .submenu:before {
  width: 62px;
  position: absolute;
  left: -62px;
  top: 0;
  bottom: 0;
  background: #5A5A55;
  content: "";
}

body .groupemenu .menu-wrapper {
  max-width: 1200px;
  margin: 0 auto;
}

body .groupemenu .menu-wrapper:after {
  content: "";
  display: table;
  clear: both;
}

body .groupemenu .submenu {
  height: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  left: 0;
  display: none;
}

body .groupemenu:hover .submenu {
  display: block;
  height: auto;
}

body .contentarea.no-sidebar .content {
  width: 100%;
  padding: 2%;
  overflow: hidden;
}

body .contentarea .content {
  padding: 2%;
}

@media screen and (min-width: 970px) {
  body .contentarea .content {
    width: 66.66667%;
    float: left;
  }
}

@media screen and (min-width: 500px) and (max-width: 970px) {
  body .contentarea .content {
    width: 66.66667%;
    float: left;
  }
}

body .contentarea .right {
  background: #F0F0DC;
}

@media screen and (min-width: 970px) {
  body .contentarea .right {
    padding: 20px 40px;
    width: 33.33333%;
    float: right;
    margin-right: 0;
  }
}

@media screen and (min-width: 500px) and (max-width: 970px) {
  body .contentarea .right {
    padding: 2%;
    width: 33.33333%;
    float: right;
    margin-right: 0;
  }
}

body header > .wrapper, body footer > .wrapper, body .bottom > .wrapper, body .submenu > .wrapper, body .contentarea > .wrapper, body .slider > .wrapper {
  max-width: 1200px;
  margin: 0 auto;
}

body header > .wrapper:after, body footer > .wrapper:after, body .bottom > .wrapper:after, body .submenu > .wrapper:after, body .contentarea > .wrapper:after, body .slider > .wrapper:after {
  content: "";
  display: table;
  clear: both;
}

body header > .wrapper:first-of-type:after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (min-width: 500px) and (max-width: 970px) {
  body header > .wrapper:first-of-type > div {
    width: 58.33333%;
    float: left;
  }
}

@media screen and (min-width: 970px) {
  body header > .wrapper:first-of-type > div {
    width: 33.33333%;
    float: left;
  }
}

@media screen and (min-width: 500px) and (max-width: 970px) {
  body header > .wrapper:first-of-type > div:nth-of-type(2) {
    width: 41.66667%;
    float: left;
  }
}

@media screen and (min-width: 970px) {
  body header > .wrapper:first-of-type > div:nth-of-type(2) {
    width: 41.66667%;
    float: left;
  }
}

@media screen and (min-width: 500px) and (max-width: 970px) {
  body header > .wrapper:first-of-type > div.top-right {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 970px) {
  body header > .wrapper:first-of-type > div.top-right {
    width: 25%;
    float: left;
  }
}

body .breadcrumbs-wrapper, body .slider-wrapper, body .subcontent, body .partners {
  margin: 0 auto;
  max-width: 1200px;
}

body header {
  background: #9B0F05;
  position: relative;
  z-index: 100;
}

body header .wrapper:last-of-type {
  position: relative;
}

body > .contentarea {
  /* background: $third; */
  min-height: 50px;
}

body .subcontent {
  margin-bottom: 20px;
}

body .subcontent:after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (min-width: 500px) and (max-width: 970px) {
  body .subcontent .basleft {
    width: 50%;
    float: left;
    padding: 0 10px;
  }
  body .subcontent .basright {
    width: 50%;
    float: left;
  }
}

@media screen and (min-width: 970px) {
  body .subcontent .basleft {
    width: 33.33333%;
    float: left;
    padding: 10px;
  }
  body .subcontent .basright {
    width: 66.66667%;
    float: left;
  }
}

body .customlogo {
  text-align: center;
}

body .customlogo img {
  max-width: 280px;
}

body .grp-partenaires {
  display: none;
}

@media screen and (min-width: 500px) {
  body .grp-partenaires {
    display: block;
  }
}

body .bottom {
  display: none;
  background: url(images/footer.jpg) center center no-repeat #585954;
  min-height: 150px;
}

@media screen and (min-width: 500px) {
  body .bottom {
    display: block;
  }
}

body .bottom > .wrapper:after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (min-width: 500px) {
  body .bottom > .wrapper > div {
    width: 25%;
    float: left;
  }
}

@media screen and (min-width: 970px) {
  body .bottom > .wrapper > div {
    width: 16.66667%;
    float: left;
  }
}

body .bottom > .wrapper > div.footer5 {
  box-sizing: border-box;
  text-align: center;
  padding: 30px;
  display: none;
}

@media screen and (min-width: 970px) {
  body .bottom > .wrapper > div.footer5 {
    width: 33.33333%;
    float: right;
    margin-right: 0;
    display: block;
  }
}

body .bottom > .wrapper > div.footer5 img {
  max-width: 70%;
}

body .bottom > .wrapper > div.footer5 strong {
  display: block;
  text-align: center;
  font-size: .8rem;
}

body footer {
  background: #5A5A55;
}

.customlogo img {
  width: 100%;
}

.partenaire {
  text-align: center;
  background: white;
}

.partenaire .wrapper > img {
  display: inline-block;
  max-height: 4vw;
  padding: 1vw;
  width: auto !important;
}

body#projet .content {
  width: 100%;
  padding: 0 2%;
}

.contact {
  padding: 10px;
}

.contact:after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (min-width: 500px) {
  .contact .contact-left {
    padding: 0 2%;
    width: 50%;
    float: left;
  }
  .contact .contact-right {
    padding: 0 2%;
    width: 50%;
    float: left;
  }
}

.bg_footer {
  display: block;
  z-index: -1;
  position: relative;
  margin-top: -250px;
}

.bg_footer .wrapper {
  margin: 0 auto;
  max-width: 1400px;
  background: url(images/logofooter.png) no-repeat transparent;
  background-position: center left;
  background-size: 250px 250px;
  height: 250px;
}

img {
  max-width: 100%;
}

.post_content.cat-9 img {
  max-width: none !important;
}

.contentarea:not(.cat-39) .content img {
  width: 600%;
  height: auto !important;
  display: inline-block;
}

.contentarea:not(.cat-39) .content img.aligncenter {
  margin: 0 auto;
  display: block;
}

.contentarea:not(.cat-39) .content img.alignright {
  float: right;
  margin-left: 10px;
}

.contentarea:not(.cat-39) .content img.alignright:after {
  content: "";
  clear: both;
  display: block;
}

.contentarea:not(.cat-39) .content img.alignleft {
  float: left;
  margin-right: 10px;
}

.contentarea:not(.cat-39) .content img.alignleft:after {
  content: "";
  clear: both;
  display: block;
}

.right img {
  max-width: 100% !important;
  height: auto !important;
  display: inline-block;
}

.contentarea.list .wrapper img {
  width: 150px !important;
}

.smooth_zoom_preloader {
  overflow: hidden;
}

li.contactmail a {
  background: url(images/mail.png) no-repeat;
  font-size: 0 !important;
  height: 15px;
  width: 100%;
  display: block;
}

li.contactmail a:hover {
  background-position: bottom left;
}

li.orange > a {
  cursor: pointer !important;
}

.menu-wrapper .menu-principal-container {
  display: none;
}

@media screen and (min-width: 970px) {
  .menu-wrapper .menu-principal-container {
    display: block;
  }
}

.menu-wrapper .menu-principal-container .menu ul {
  display: none;
}

.menu-wrapper .menu {
  background: #9B0F05;
  height: 43px;
}

.menu-wrapper .menu:after {
  content: "";
  display: table;
  clear: both;
}

.menu-wrapper .menu li a,
.menu-wrapper .menu li span {
  display: block;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
  color: #F09100;
}

.menu-wrapper .menu > li {
  z-index: 100000000000000000;
  position: relative;
}

.menu-wrapper .menu > li:hover {
  background: #910e05;
}

.menu-wrapper .menu > li > a {
  font-family: 'Kreon', serif;
}

.menu-wrapper .menu > li > ul {
  z-index: 100;
  position: absolute;
  right: 0;
  left: 0;
  background: #5A5A55;
  top: 42px;
}

.menu-wrapper .menu > li > ul > li a,
.menu-wrapper .menu > li > ul > li span {
  color: white;
  font-family: 'Open Sans', sans-serif;
  font-size: .7rem;
  text-transform: none;
}

.menu-wrapper .menu > li > ul > li:hover {
  background: #74746e;
}

.sub-menu .sub-menu {
  background: #74746e;
  margin: 0;
  padding: 0 !important;
}

.sub-menu .sub-menu li {
  padding: 0 !important;
}

.submenu {
  display: block;
  background: #5A5A55;
}

.submenu .menu-principal-container .menu > li > a {
  display: none;
}

.submenu .menu-principal-container .menu ul {
  padding: 10px 0;
  display: block;
}

.submenu .menu-principal-container .menu ul:hover {
  background: #4d4d49;
}

.submenu .menu-principal-container .menu ul > li {
  display: block;
}

.submenu .menu-principal-container .menu ul > li a {
  display: block;
  padding: 5px;
  font-size: .8rem;
  color: white;
}

.submenu .menu-principal-container .menu ul > li a.current.active, .submenu .menu-principal-container .menu ul > li a:hover {
  background: #9B0F05;
}

.toggle-menu {
  cursor: pointer;
  position: fixed;
  top: 20px;
  left: 10px;
  text-align: center;
  background: white;
  z-index: 1000001;
  border: 1px solid #9B0F05;
}

.toggle-menu span {
  display: inline-block;
  padding: 3px 10px;
  font-size: 2.5rem;
  color: #9B0F05;
}

@media screen and (min-width: 970px) {
  .toggle-menu.mintop {
    top: 0;
    left: 0;
  }
}

.menumobile {
  overflow-y: auto;
  display: none;
  -moz-box-shadow: 1px 0px 1px 0px lightgrey;
  -webkit-box-shadow: 1px 0px 1px 0px lightgrey;
  -o-box-shadow: 1px 0px 1px 0px lightgrey;
  box-shadow: 1px 0px 1px 0px lightgrey;
  filter: progid:DXImageTransform.Microsoft.Shadow(color=#656565, Direction=90, Strength=1);
  z-index: 1000000;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  background: white;
  padding-top: 100px;
  min-width: 250px;
}

.menumobile ul.menu li a,
.menumobile ul.menu li span {
  display: block;
  padding: 5px 10px;
  text-align: center;
  text-transform: uppercase;
  color: #9B0F05;
  font-family: 'Kreon', serif;
  text-decoration: none;
  letter-spacing: 1px;
  text-align: left;
}

.menumobile ul.menu li a:hover,
.menumobile ul.menu li span:hover {
  background: #9B0F05;
  color: white;
}

.menumobile ul.menu li > ul li a,
.menumobile ul.menu li > ul li span {
  font-family: 'Open Sans', sans-serif;
  font-size: .9rem;
  letter-spacing: 0;
  text-align: left;
  display: block;
  padding: 1px 10px;
  text-transform: none;
  color: #F09100;
}

.menumobile ul.menu li > ul li a:hover,
.menumobile ul.menu li > ul li span:hover {
  background: #9B0F05;
  color: white;
}

.bottom .menu span {
  font-size: .8rem;
  font-weight: 600;
  margin: 10px 0 5px 0;
  display: block;
  color: #F09100;
  text-transform: uppercase;
}

.bottom .menu a {
  color: white;
  font-size: .7rem;
}

div[class^="footer"] {
  padding: 0px 2% 20px 2%;
}

div[class^="footer"] .menu-item-has-children > a, div[class^="footer"] .orange > a, div[class^="footer"] a[href="#"], div[class^="footer"] .menu > li:first-of-type > a {
  display: inline-block;
  padding: 30px 0 5px 0;
  cursor: default;
  color: #F09100;
  font-weight: 700;
  text-transform: uppercase;
}

div[class^="footer"] a {
  font-size: .8rem;
}

div[class^="footer"] a:hover {
  color: #F09100;
}

/* --------------------------------------
  > tablette
-------------------------------------- */
@media screen and (min-width: 970px) {
  header .menu-wrapper .menu > li {
    width: 16.66667%;
    float: left;
    min-height: 1px;
    border-bottom: 7px transparent solid;
  }
  header .menu-wrapper .menu > li.current.active, header .menu-wrapper .menu > li:hover {
    border-color: #F09100;
  }
  header .submenu .menu:after {
    content: "";
    display: table;
    clear: both;
  }
  header .submenu .menu > li {
    text-align: center;
    width: 16.66667%;
    float: left;
    min-height: 1px;
  }
}

/* ------------------------------------------
   MODULES
------------------------------------------ */
#copyright {
  text-align: center;
  color: white;
  font-size: .8rem;
  padding: .2rem;
}

#copyright a {
  color: #F09100;
  font-size: .7rem;
  padding: .2rem;
}

#copyright a:hover {
  text-decoration: underline;
  color: #ffbd57;
}

.slogan {
  font-size: 1.6rem;
  text-align: center;
}

@media screen and (min-width: 500px) {
  .slogan {
    text-align: left;
    margin-top: 95px;
    margin-bottom: 20px;
  }
}

body:not(#projet) .slogan {
  display: none;
}

@media screen and (min-width: 500px) {
  body:not(#projet) .slogan {
    display: block;
  }
}

.part2 {
  position: relative;
  background: #F0F0DC;
  margin-top: 40px;
  margin-bottom: 40px;
}

@media screen and (min-width: 970px) {
  .part2:before {
    background-size: contain;
    content: "";
    position: absolute;
    width: 50%;
    height: 450px;
    display: inline-block;
    background: url(images/modelisation.png) no-repeat #F0F0DC;
    background-position: center right;
  }
}

body .contentarea .customprojetcadomius {
  margin: 35px 0;
  min-height: 370px;
  /* background-size: 775px 380px; */
}

@media screen and (min-width: 970px) {
  body .contentarea .customprojetcadomius {
    max-width: 1200px;
    margin: 0 auto;
  }
}

body .contentarea .customprojetcadomius:after {
  content: "";
  display: table;
  clear: both;
}

body .contentarea .customprojetcadomius .right {
  width: 100%;
  background: #F0F0DC;
  text-align: center;
  margin-top: 20px;
  float: right;
  text-align: center;
}

@media screen and (min-width: 970px) {
  body .contentarea .customprojetcadomius .right {
    width: 50%;
    padding: 0 1% 1%;
    background: transparent;
  }
}

body .contentarea .customprojetcadomius .right h1 {
  margin-top: -40px;
  text-align: center;
  font-size: 2rem;
  color: #9B0F05;
}

body .contentarea .customprojetcadomius .right p {
  text-align: center;
  padding: 10px;
  font-size: .9rem;
}

body .contentarea .customprojetcadomius .right .ensavoirplus {
  margin: 30px auto;
}

body .contentarea .customprojetcadomius .menupicto:after {
  content: "";
  display: table;
  clear: both;
}

body .contentarea .customprojetcadomius .menupicto li {
  height: 100px;
  vertical-align: top;
  display: inline-block;
  width: 26%;
  margin: 0 2%;
  border-radius: 4px;
}

body .contentarea .customprojetcadomius .menupicto li:nth-of-type(1) {
  background: #00AF96;
}

body .contentarea .customprojetcadomius .menupicto li:nth-of-type(2) {
  background: #7DAF23;
}

body .contentarea .customprojetcadomius .menupicto li:nth-of-type(3) {
  background: #F09100;
}

body .contentarea .customprojetcadomius .menupicto li:hover {
  background: #5A5A55;
}

body .contentarea .customprojetcadomius .menupicto li a {
  color: white;
}

body .contentarea .customprojetcadomius .menupicto li a i[class^="cadomus-ico-"] {
  height: 75px;
  font-size: 60px;
  line-height: 80px;
  display: block;
}

body .contentarea .customprojetcadomius .menupicto li a i.cadomus-ico-contrat {
  font-size: 90px;
}

body .contentarea .customprojetcadomius .menupicto li a .kreon-picto-txt {
  font-size: .6rem;
  text-transform: uppercase;
}

.ensavoirplus a {
  text-transform: uppercase;
  display: inline-block;
  color: white;
  padding: 10px;
  background: #5A5A55;
  font-size: .8rem;
}

.ensavoirplus a:hover {
  background: #9B0F05;
}

.subcontent .custom.suivez {
  display: none;
  padding-bottom: 43px;
  text-align: center;
}

@media screen and (min-width: 970px) {
  .subcontent .custom.suivez {
    display: block;
  }
}

.subcontent .custom.suivez .suivezcadomus {
  padding-top: 20px;
  font-size: 2.2rem;
  margin-bottom: 20px;
  color: #9B0F05;
}

.subcontent .custom.suivez ul li {
  display: inline-block;
}

.subcontent .custom.suivez ul a {
  color: #5A5A55;
  display: block;
  font-size: 2rem;
}

.subcontent .custom.suivez ul a:hover {
  color: #9B0F05;
}

.subcontent .nav.menu {
  text-transform: uppercase;
}

.subcontent .nav.menu li {
  position: relative;
  background: #9B0F05;
  border-bottom: 7px solid white;
  color: white;
}

.subcontent .nav.menu li:hover {
  background: #5A5A55;
}

.subcontent .nav.menu li:before {
  display: inline-block;
  padding: 4px 16px;
  font-size: 3rem;
  font-family: 'cadomus' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

.subcontent .nav.menu li a {
  padding-top: 23px;
  padding-left: 8px;
  position: absolute;
  right: 0;
  left: 80px;
  bottom: 0;
  top: 0;
  color: white;
  font-size: .8rem;
}

.subcontent .nav.menu li a:hover {
  background: #5A5A55;
}

.subcontent .nav.menu li:nth-of-type(1):before {
  content: "\e90b";
}

.subcontent .nav.menu li:nth-of-type(2):before {
  content: "\e904";
}

.subcontent .nav.menu li:nth-of-type(3):before {
  content: "\e90c";
}

.subcontent .nav.menu li:nth-of-type(4):before {
  content: "\e90a";
}

.subcontent .nav.menu .basleft {
  width: 33.33333%;
  float: left;
  padding: 10px;
}

.subcontent .nav.menu .basright {
  width: 66.66667%;
  float: left;
}

/* -------------------------------------------------
   SOUTENEZ + SOCIAL
------------------------------------------------- */
.custom.social {
  padding-top: 10px;
}

.custom.social ul {
  position: absolute;
  right: 10px;
  top: 10px;
}

@media screen and (min-width: 970px) {
  .custom.social ul {
    position: static;
  }
}

.custom.social ul > li {
  display: inline-block;
}

.custom.social ul > li [class^="cadomus-ico-"] {
  color: white;
  font-size: 1.3rem;
}

a.soutenez {
  margin-top: 10px;
  width: 100%;
  color: #9B0F05;
  background: #F09100;
  border-radius: 7px;
  display: none;
}

a.soutenez:after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (min-width: 500px) {
  a.soutenez {
    display: block;
  }
}

@media screen and (min-width: 500px) and (max-width: 970px) {
  a.soutenez {
    margin-top: 0;
    position: absolute;
    top: 10px;
    right: 100px;
    width: 250px;
  }
}

a.soutenez .soutenez-txt {
  display: block;
  float: left;
  text-transform: uppercase;
  font-family: 'Kreon', serif;
  padding: 20px 5px;
  font-size: 1.3rem;
}

@media screen and (min-width: 970px) and (max-width: 1200px) {
  a.soutenez .soutenez-txt {
    font-size: 1.3vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 970px) {
  a.soutenez .soutenez-txt {
    font-size: 1rem;
    padding: 5px;
  }
}

a.soutenez .cadomus-ico-contrat {
  padding: 0 10px;
  display: block;
  float: left;
  font-size: 60px;
}

@media screen and (min-width: 500px) and (max-width: 970px) {
  a.soutenez .cadomus-ico-contrat {
    font-size: 30px;
  }
}

a.soutenez:hover {
  background: white;
}

/* --------------------------------------
   SOCIAL
-------------------------------------- */
.social.partage {
  width: 16.66667%;
  float: left;
  padding-top: 8px;
  padding-bottom: 12px;
  border-bottom: 1px solid #F09100;
  color: #F09100;
}

/* ------------------------------------------
   Breadcrumb
------------------------------------------ */
#breadcrumbs {
  font-size: .9rem;
}

#breadcrumbs > span {
  display: inline-block;
  padding: 8px 0;
}

#breadcrumbs a {
  color: black;
  text-decoration: underline;
}

/* ------------------------------------------
   Search
------------------------------------------ */
#searchform {
  background: #ebebd1;
  border-bottom: 1px solid lightgrey;
  font-size: 0;
}

@media screen and (min-width: 970px) {
  #searchform {
    background: white;
  }
}

#searchform > div {
  overflow: hidden;
  height: 36px;
  background: white;
  position: relative;
}

#searchform > div:after {
  content: "";
  display: table;
  clear: both;
}

#searchform > div:after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'cadomus' !important;
  content: "\e90d";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: black;
  display: inline-block;
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
  width: 32px;
  vertical-align: bottom;
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
}

#searchform input {
  border: none;
  font-size: 1rem;
}

#searchform input[type="text"] {
  min-width: 400px;
  display: block;
  padding-left: 40px;
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
}

#searchform input[type="text"]:focus {
  background: #F0F0DC;
}

#searchform input[type="submit"] {
  display: none;
  float: right;
  margin-bottom: -1px;
}

#searchform input[type="submit"]:hover {
  background: #5A5A55;
  color: white;
}

/* contact */
.contact {
  background: url(images/logofooter.png) no-repeat transparent;
  background-position: 90% 90%;
}

.contact p {
  line-height: 30px;
}

.contact-right {
  background: white;
}

.contact-left > div[role="form"] {
  background-color: #fafafa;
  border: 1px solid #eeeeee;
  padding: 2%;
}

.contact-left > div[role="form"] input, .contact-left > div[role="form"] textarea {
  border: 1px solid #ddd;
  color: #555555;
  font-size: 14px;
  line-height: 20px !important;
  visibility: visible;
  opacity: 1;
}

.contact-left > div[role="form"] input[type="submit"], .contact-left > div[role="form"] textarea[type="submit"] {
  background: #F09100;
  color: white;
}

.contact-left > div[role="form"] input[type="submit"]:hover, .contact-left > div[role="form"] textarea[type="submit"]:hover {
  background: #9B0F05;
}

.miniwatermark {
  right: 3vw;
  bottom: 3vw;
  height: 8vw;
  width: 8vw;
  position: absolute;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(images/watermark.png);
}

@media screen and (min-width: 500px) and (max-width: 970px) {
  .miniwatermark {
    right: 2vw;
    bottom: 2vw;
    height: 6vw;
    width: 6vw;
  }
}

@media screen and (min-width: 970px) {
  .miniwatermark {
    right: 1vw;
    bottom: 1vw;
    height: 4vw;
    width: 4vw;
  }
}

.list-categorie > div {
  border-bottom: #F09100 solid 1px;
  position: relative;
  padding: 10px 0;
}

.list-categorie > div > a, .list-categorie > div > div {
  display: block;
}

.list-categorie > div > a:after, .list-categorie > div > div:after {
  content: "";
  display: table;
  clear: both;
}

.list-categorie > div > a .thumb, .list-categorie > div > div .thumb {
  min-width: 150px;
  min-height: 150px;
  background-size: contain;
  background-repeat: no-repeat;
}

@media screen and (min-width: 970px) {
  .list-categorie > div > a .thumb, .list-categorie > div > div .thumb {
    background-position: center;
  }
}

@media screen and (min-width: 970px) {
  .list-categorie > div > a .thumb, .list-categorie > div > div .thumb {
    width: 20%;
    float: left;
  }
}

@media screen and (min-width: 970px) {
  .list-categorie > div > a .content-info, .list-categorie > div > div .content-info {
    width: 70%;
    float: left;
  }
}

.list-categorie > div > a:hover, .list-categorie > div > div:hover {
  background: #F0F0DC;
  /* 				.thumb,.thumb.default, .lesaviezvous .thumb{
	background-color: black;
} */
}

.list-categorie > div > a.part-scien, .list-categorie > div > div.part-scien {
  border-right: #9B0F05 10px solid;
}

.list-categorie > div > a.part-insti, .list-categorie > div > div.part-insti {
  border-right: #F09100 10px solid;
}

.list-categorie > div > a.part-prive, .list-categorie > div > div.part-prive {
  border-right: #F0F0DC 10px solid;
}

.list-categorie span.date {
  position: absolute;
  left: 0;
  top: 20px;
  background: #9B0F05;
  color: white;
  font-weight: bold;
  padding: 0px 5px;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}

@media screen and (min-width: 970px) {
  .list-categorie span.date {
    bottom: 0px;
    top: auto;
  }
}

.list-categorie .content-info {
  padding-left: 20px;
}

.list-categorie .content-info [class*="-posttitle"] {
  color: #9B0F05;
  padding: 8px 0;
}

.list-categorie .content-info h2 {
  margin: 0;
  line-height: 1.5rem;
}

.list-categorie .content-info .excerpt {
  color: #5A5A55;
  padding: 10px 0;
}

.list-categorie .content-info .excerpt > p {
  margin: 0;
}

.list-categorie .post-categories {
  text-align: right;
  font-size: .8rem;
}

.list-categorie .post-categories li {
  padding: 0 5px;
  display: inline-block;
  border-right: 1px solid lightgrey;
}

.list-categorie .post-categories li:last-of-type {
  border-right: none;
}

.list-categorie .post-categories li a {
  color: #F09100;
}

.nocontent {
  background: #5A5A55;
  padding: 50px 0;
  text-align: center;
}

.nocontent h1 {
  padding: 20px;
  color: #F09100;
}

.nocontent img {
  width: 80%;
  margin: 0 auto;
}

/* .thumb,.thumb.default, .lesaviezvous .thumb{
	background-color: $sixth;
	
	@media screen and (min-width:$containermedium) {; }
}
 */
.list-categorie > div.sousgalerie {
  padding-left: 20px;
  border-left: 8px solid lightgrey;
}

.post_content.parent#post-263 {
  display: none;
}

.associate-tag a {
  color: #F09100;
  text-transform: uppercase;
  font-size: .8rem;
}

.date {
  margin-bottom: 10px;
}

.content-single {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: justify;
}

ul.galerie:after {
  content: "";
  display: table;
  clear: both;
}

ul.galerie > li {
  list-style-type: none;
}

@media screen and (min-width: 500px) and (max-width: 970px) {
  ul.galerie > li {
    width: 50%;
    float: left;
  }
}

@media screen and (min-width: 970px) {
  ul.galerie > li {
    width: 33.33333%;
    float: left;
  }
}

/* ------------------------------------------
   JQUERY PAN -> CARTOGRAPHIE
------------------------------------------ */
/* .pan{

	@media screen and (min-width:$containersmall) { display: block;}
	cursor:pointer;
	position: relative;
	overflow: hidden;

	&:after{
		@include transition(right .5s ease);
	
		content: "\e90d";
		font-family: 'cadomus' !important;
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;
		font-size: 2rem;
		color: white;
		position: absolute;
		bottom: 3px;
		right: -600px;

		background: rgba(0, 0, 0, 0.5);
		padding: 40px;
		border-top-left-radius:25px ;		
		display: block;


	}
	&:hover:after{
		display: block;
		right: 3px;
		
	}

}
.contentarea .content .panmobile{
	display: none;	
		@media screen and (min-width:$containersmall) { display: none;	 }
		&.zoom{
			img{width: 600%;}	


		}
		overflow:hidden;	
	img{
		max-width: 600% !important;	
		width: 100%;


	}

}
.panWrapper {
	position:fixed; width:100%; height:100%; overflow:hidden; background-color:rgba(0,0,0,0.9); z-index:9999; left:0; top:0;bottom: 0;	right: 0;	 display:none; cursor:move;
	&:after{
		content:""; display: block;	
		position:fixed;right:3vw;bottom:3vw;height: 6vw;width: 6vw; 
		background-position:center center;   background-size: contain; background-repeat:no-repeat; background-image:url(images/watermark.png);
	}
}
.panWrapper * {-moz-user-select: -moz-none;-khtml-user-select: none;-webkit-user-select: none;-o-user-select: none;user-select: none;}
body .panWrapper img{max-width: 600% !important;}
.panWrapper a.controls {background-size:100%; width:32px; height:32px; display:block; position:fixed; left:50%; z-index:2; bottom:30px; cursor:pointer;}
.panWrapper a.controls.in{background-image:url('images/zoomIn.png'); margin-left:-30px;}
.panWrapper a.controls.out{background-image:url('images/zoomOut.png'); margin-left:30px;}
.panWrapper a.controls.close{background-image:url('images/close.png'); left:auto; right:30px; top:30px;}
.panWrapper .loading{z-index:3; position:absolute; left:50%; top:50%; margin:-50px 0 0 -50px; width:100px; height:100px; background-image:url(images/loading.gif); background-repeat:no-repeat; background-size:100%; display:block;}	

 */
div.carte {
  height: 500px !important;
  overflow: hidden;
}

div.carte .smooth_zoom_preloader {
  height: 500px !important;
}

/* ------------------------------------------
   ARTICLES
------------------------------------------ */
ul.galerie li {
  padding: 10px;
}

ul.galerie a {
  position: relative;
  display: block;
}

ul.galerie a > span {
  position: absolute;
  top: 10px;
  right: 0;
  background: #9B0F05;
  padding: 2px 2%;
  font-weight: bold;
  display: inline-block;
  color: white;
  font-size: .7rem;
}

ul.galerie a:after {
  content: "\e90d";
  font-family: 'cadomus' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 2rem;
  color: white;
  position: absolute;
  bottom: 3px;
  right: 3px;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 100px;
  display: none;
}

ul.galerie a:hover > span {
  background: #F09100;
}

ul.galerie a:hover:after {
  display: block;
}

ul.galerie .galerie-thumb {
  display: block;
  height: 188px;
  background-position: center;
  background-color: #F0F0DC;
  min-width: 150px;
  min-height: 150px;
  background-size: contain;
  background-repeat: no-repeat;
}

ul.galerie .galerie-thumb.video {
  background-color: black;
}

/* ------------------------------------------
   Vidéo
------------------------------------------ */
.iframe-responsive-wrapper {
  position: relative;
}

.iframe-responsive-wrapper .iframe-ratio {
  display: block;
  width: 100%;
  height: auto;
}

.iframe-responsive-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.twitterwidget iframe {
  width: 100%;
}

.video-description {
  padding-top: 20px;
  padding-bottom: 20px;
}

/* ------------------------------------------
   LOGO PARTENAIRES
------------------------------------------ */
.logo-partenaire {
  max-width: 170px;
  width: 15%;
  display: inline-block;
  height: 90px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.grp-partenaires {
  padding: 10px;
  text-align: center;
}

/* ------------------------------------------
   ARTICLES
------------------------------------------ */
.articles-with-intro .content-with-social .content-social {
  top: 0;
}

.articles-with-intro .content-with-social .content-social .btn-partage {
  padding: 10px 0;
}

@media screen and (min-width: 970px) {
  .articles-with-intro .content-with-social .content-social {
    width: 16.66667%;
    float: left;
    padding-right: 10px;
  }
  .articles-with-intro .content-with-social .content-social .btn-partage {
    float: none;
    padding: 0;
  }
}

@media screen and (min-width: 970px) {
  .articles-with-intro .content-with-social .content-info {
    width: 83.33333%;
    float: left;
  }
}

.btn-partage a {
  color: #5A5A55;
  font-size: 1.3rem;
}

.btn-partage a:hover {
  color: #F09100;
}

.content-social {
  position: relative;
  top: -20px;
}

.content-social .btn-partage {
  display: inline-block;
  float: right;
}

.content-social strong {
  display: inline-block;
  padding: 8px 0;
  font-weight: normal;
  color: #F09100;
}

.content-social hr {
  border-bottom: none;
}

#page-404 {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.message-404 {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  background: url(images/einstein.jpg) center center no-repeat #2E2E2E;
  background-size: contain;
}

.message-404 .right404 {
  min-width: 350px;
  margin-top: 50vh;
  /* poussé de la moitié de hauteur de viewport */
  transform: translateY(-50%);
  /* tiré de la moitié de sa propre hauteur */
  width: 50%;
  float: right;
}

.message-404 h1, .message-404 h2, .message-404 h3 {
  color: white;
  padding: 2vw;
  text-align: center;
}

.wp-caption-text {
  text-align: center;
  color: #F09100;
  font-size: .8rem;
}

div.plan-du-site:after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (min-width: 970px) {
  div.plan-du-site > div {
    width: 33.33333%;
    float: left;
  }
}

form input[type="text"], form input[type="email"], form textarea {
  max-width: 90%;
}

div.block-soutenez {
  text-align: justify;
  border-radius: 5px;
  margin: 30px;
  padding: 40px;
  background: #ffe5bd;
}

div.block-soutenez hr {
  color: white;
  border-color: white;
  margin: 10px;
}

div.block-soutenez:after {
  content: "";
  display: table;
  clear: both;
}

div.block-soutenez .orange {
  color: #F09100;
}

div.block-soutenez .rouge {
  color: #9B0F05;
}

div.block-soutenez .titre-soutenez {
  font-size: 3rem;
}

div.block-soutenez .titre-soutenez:before {
  content: "";
  height: 150px;
  width: 150px;
  background-color: #9B0F05;
  display: block;
  float: left;
  margin-right: 30px;
  background-image: url(images/logotransparent.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 270px 200px;
}

div.block-soutenez .left-soutenez {
  padding: 15px;
}

@media screen and (min-width: 970px) {
  div.block-soutenez .left-soutenez {
    float: left;
    width: 30%;
  }
}

div.block-soutenez .right-soutenez {
  padding: 15px;
  color: #9B0F05;
}

@media screen and (min-width: 970px) {
  div.block-soutenez .right-soutenez {
    float: right;
    width: 70%;
  }
}

div.block-soutenez .soutenez-item {
  padding: 30px  30px 30px 100px;
  margin: 10px;
  background: #ffbd57;
  position: relative;
}

div.block-soutenez .soutenez-item:before {
  font-family: 'cadomus' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  content: "\e315";
  display: block;
  float: left;
  color: white;
  width: 50px;
  font-size: 4rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  padding-top: 20px;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.smooth_zoom_preloader {
  background-image: url(images/preloader.gif) !important;
}

.smooth_zoom_icons {
  background-image: url(images/icons.png) !important;
}

.carte {
  position: relative;
}

.associate-tag a {
  margin-left: 4px;
}

/* ------------------------------------------
   RIGHT
------------------------------------------ */
.right {
  margin-top: 40px;
  margin-bottom: 20px;
}

.right .picto-haut {
  bottom: 35px;
  height: 70px;
  overflow: hidden;
  position: relative;
  text-align: center;
}

@media screen and (min-width: 970px) {
  .right .picto-haut {
    bottom: 52px;
  }
}

.right .picto-haut [class^="cadomus-ico-"] {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.right .picto-haut .cadomus-ico-fond {
  font-size: 0;
  margin-top: -31px;
  z-index: 0;
  color: #9B0F05;
}

.right .picto-haut .cadomus-ico-fond:before, .right .picto-haut .cadomus-ico-fond:after {
  font-size: 8rem;
}

.right .picto-haut .cadomus-ico-livre {
  font-size: 0;
  z-index: 10;
  color: white;
  margin-top: 11px;
}

.right .picto-haut .cadomus-ico-livre:before, .right .picto-haut .cadomus-ico-livre:after {
  font-size: 3rem;
}

.right .picto-haut .cadomus-ico-caen {
  font-size: 0;
  z-index: 10;
  color: white;
  margin-top: 9px;
}

.right .picto-haut .cadomus-ico-caen:before, .right .picto-haut .cadomus-ico-caen:after {
  font-size: 3rem;
}

/* ------------------------------------------
   ARTICLES CONNEXES
------------------------------------------ */
.articles-connexes {
  text-align: center;
}

.articles-connexes h2 {
  margin-top: -25px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 1.2rem;
}

@media screen and (min-width: 970px) {
  .articles-connexes h2 {
    font-size: 2rem;
    margin-top: -50px;
  }
}

.articles-connexes h3 {
  font-size: 1rem;
  text-align: center;
  margin-bottom: 15px;
}

@media screen and (min-width: 970px) {
  .articles-connexes h3 {
    text-align: left;
    font-size: 1rem;
  }
}

@media screen and (min-width: 970px) {
  .articles-connexes {
    text-align: left;
  }
}

@media screen and (max-width: 970px) {
  .right .articles-connexes img {
    max-width: 60% !important;
  }
}

.twitter-header {
  background: #9B0F05;
}

.twitter-header h2 {
  padding: 10px;
  font-size: 2rem;
  vertical-align: middle;
  color: white;
}

.twitter-header h2:before {
  display: inline-block;
  content: "\e900";
  font-family: 'cadomus' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}

.loader {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #9B0F05;
  background-image: url(images/preloader.gif) !important;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.togglemenu,
.closeanime,
.anime {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.anime.left {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}

.anime.right,
.togglemenu {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}

.anime.top {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

.closeanime.left {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
}

.closeanime.right {
  -webkit-animation-name: slideOutRight;
  animation-name: slideOutRight;
}

.closeanime.top {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp;
}

.anime.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.anime.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.anime.flipOutX,
.anime.flipOutY,
.anime.bounceIn,
.anime.bounceOut {
  -webkit-animation-duration: .75s;
  animation-duration: .75s;
}

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}

@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}

@-webkit-keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

.slideOutDown {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown;
}

@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
}

@-webkit-keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

.slideOutRight {
  -webkit-animation-name: slideOutRight;
  animation-name: slideOutRight;
}

@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

.slideOutUp {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp;
}

/*! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  vertical-align: top;
}

.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8020;
}

.fancybox-skin {
  position: relative;
  /* background: #f9f9f9; */
  background: black;
  color: #444;
  text-shadow: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.fancybox-opened {
  z-index: 8030;
}

.fancybox-opened .fancybox-skin {
  -webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
}

.fancybox-outer, .fancybox-inner {
  position: relative;
}

.fancybox-inner {
  overflow: hidden;
}

.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch;
}

.fancybox-error {
  color: #444;
  font: 14px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  margin: 0;
  padding: 15px;
  white-space: nowrap;
}

.fancybox-image, .fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%;
}

.fancybox-image {
  max-width: 100%;
  max-height: 100%;
}

#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
  background-image: url("images/fancy/fancybox_sprite.png");
}

#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  background-position: 0 -108px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 8060;
}

#fancybox-loading div {
  width: 44px;
  height: 44px;
  background: url("images/fancy/fancybox_loading.gif") center center no-repeat;
}

.fancybox-close {
  position: absolute;
  top: 0;
  right: 0;
  /* 	top: -18px;
right: -18px; */
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 8040;
}

.fancybox-nav {
  position: absolute;
  top: 0;
  width: 30%;
  height: 90%;
  cursor: pointer;
  text-decoration: none;
  background: transparent url("images/fancy/blank.gif");
  /* helps IE */
  -webkit-tap-highlight-color: transparent;
  z-index: 8040;
}

.fancybox-prev {
  left: 0;
}

.fancybox-next {
  right: 0;
}

.fancybox-nav span {
  position: absolute;
  top: 50%;
  width: 36px;
  height: 34px;
  margin-top: -18px;
  cursor: pointer;
  z-index: 8040;
  visibility: hidden;
}

.fancybox-prev span {
  left: 10px;
  background-position: 0 -36px;
}

.fancybox-next span {
  right: 10px;
  background-position: 0 -72px;
}

.fancybox-nav:hover span {
  visibility: visible;
}

.fancybox-tmp {
  position: absolute;
  top: -99999px;
  left: -99999px;
  visibility: hidden;
  max-width: 99999px;
  max-height: 99999px;
  overflow: visible !important;
}

/* Overlay helper */
.fancybox-lock {
  overflow: hidden !important;
  width: auto;
}

.fancybox-lock body {
  overflow: hidden !important;
}

.fancybox-lock-test {
  overflow-y: hidden !important;
}

.fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 8010;
  background: rgba(0, 0, 0, 0.9);
}

.fancybox-overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0;
}

.fancybox-lock .fancybox-overlay {
  overflow: auto;
  overflow-y: scroll;
}

/* Title helper */
.fancybox-title {
  visibility: hidden;
  font: normal 13px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  position: relative;
  text-shadow: none;
  z-index: 8050;
  /* 	position: absolute;
bottom: 0;	right: 0;	left: 0; */
  background: white;
  padding: 10px;
  /* 	margin-right: 15px;	margin-left: 15px;
	margin-bottom: 15px; */
}

.fancybox-opened .fancybox-title {
  visibility: visible;
}

.fancybox-title-float-wrap {
  position: absolute;
  bottom: 0;
  right: 50%;
  margin-bottom: -35px;
  z-index: 8050;
  text-align: center;
}

.fancybox-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  padding: 2px 20px;
  background: transparent;
  /* Fallback for web browsers that doesn't support RGBa */
  background: rgba(0, 0, 0, 0.8);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  text-shadow: 0 1px 2px #222;
  color: #FFF;
  font-weight: bold;
  line-height: 24px;
  white-space: nowrap;
}

.fancybox-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: #fff;
}

.fancybox-title-inside-wrap {
  padding-top: 10px;
}

.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  padding: 10px;
  background: #000;
  background: rgba(0, 0, 0, 0.8);
}

/*Retina graphics!*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
  #fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
    background-image: url("images/fancy/fancybox_sprite@2x.png");
    background-size: 44px 152px;
    /*The size of the normal image, half the size of the hi-res image*/
  }
  #fancybox-loading div {
    background-image: url("images/fancy/fancybox_loading@2x.gif");
    background-size: 24px 24px;
    /*The size of the normal image, half the size of the hi-res image*/
  }
}

.fancybox-title.fancybox-title-inside-wrap {
  text-align: center;
}

.fancybox-title.fancybox-title-inside-wrap small {
  display: block;
  color: darkgrey;
}

.fancybox-type-iframe .watermark {
  display: none;
}

.fancybox-type-iframe .watermark + div {
  display: none;
}

.watermark {
  opacity: 0;
}

.migla-panel-body.form-horizontal .form-group.mg_giving-levels > div:first-of-type label.mg_control-label {
  display: none;
}

#migla_donation_form > .migla-panel:nth-of-type(2) .migla-panel-body.form-horizontal {
  padding-bottom: 0;
}

#migla_donation_form > .migla-panel:nth-of-type(2) .migla-panel-body.form-horizontal .col-sm-3 {
  display: none;
}

#migla_donation_form > .migla-panel:nth-of-type(2) .migla-panel-body.form-horizontal .col-sm-6 {
  width: auto;
}

#migla_donation_form > .migla-panel:nth-of-type(2):after {
  content: "Les membres de l'association sont tous bénévoles. La totalité des dons est utilisée pour la réalisation du projet. Aussi, afin de limiter les frais de gestion pour l'association, les reçus fiscaux ne sont pas délivrés pour des versements inférieurs à 15 euros.";
  display: block;
  padding: 0 20px 20px 20px;
  text-align: justify;
  font-style: italic;
}

#migla_donation_form .migla_amount_lbl > div {
  font-size: 0;
}

body header.migla-panel-heading {
  position: static !important;
}

#dgx-donate-container > div span[id^='dgx_donate_giving_level'], #dgx-donate-container > div #other_radio_button {
  padding: 10px;
}

#dgx-donate-container > div span[id^='dgx_donate_giving_level'] input, #dgx-donate-container > div #other_radio_button input {
  margin-right: 5px;
}

#dgx-donate-container > div#dgx-donate-form-donation-section, #dgx-donate-container > div#dgx-donate-form-donor-section, #dgx-donate-container > div#dgx-donate-form-billing-section {
  background-color: #fafafa;
  border: 1px solid #eeeeee;
  padding: 0 20px 20px 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

#dgx-donate-container > div#dgx-donate-form-donation-section > div, #dgx-donate-container > div#dgx-donate-form-donor-section > div, #dgx-donate-container > div#dgx-donate-form-billing-section > div {
  min-height: 50px;
  clear: both;
}

#dgx-donate-container > div#dgx-donate-form-donation-section > div input, #dgx-donate-container > div#dgx-donate-form-donation-section > div select, #dgx-donate-container > div#dgx-donate-form-donor-section > div input, #dgx-donate-container > div#dgx-donate-form-donor-section > div select, #dgx-donate-container > div#dgx-donate-form-billing-section > div input, #dgx-donate-container > div#dgx-donate-form-billing-section > div select {
  border: 1px solid #ddd;
  border-radius: 5px;
}

#_dgx_donate_donor_first_name input {
  margin-left: 30px;
}

#_dgx_donate_donor_last_name input {
  margin-left: 55px;
}

#_dgx_donate_honoree_name input {
  margin-left: 96px;
}

#_dgx_donate_donor_email input {
  margin-left: 33px;
}

#_dgx_donate_donor_phone input {
  margin-left: 14px;
}

#_dgx_donate_donor_address input {
  margin-left: 42px;
}

#_dgx_donate_donor_address2 input {
  margin-left: 28px;
}

#_dgx_donate_donor_city input {
  margin-left: 70px;
}

#_dgx_donate_donor_state input {
  margin-left: 10px;
}

#_dgx_donate_donor_province input {
  margin-left: 70px;
}

#_dgx_donate_donor_country select {
  margin-left: 65px;
}

#_dgx_donate_donor_zip input {
  margin-left: 10px;
}

#dgx-donate-pay-enabled input[type="image"] {
  border: none;
  max-width: 200px;
}

/* @import "galerie"; */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* --------------------------------------
   CUSTOM            
-------------------------------------- */
/* .slider{
    .toutarticles{

        .cadomus-ico-livre{
            display: block;
            font-size: 4rem;
        }
        .kreon-txt{
            display: block;
            font-size: .3rem;
        }
        &:hover{
            background: $sixth;
        }
    }

>.wrapper {
    position: relative;
    .toutarticles{
        position: absolute;
        right:30px;bottom: 20px;
        background: $first;
        display: inline-block;
        text-align: center;
        max-width: 130px;
        padding: 12px 25px;
        border-radius: 7px;
        color:white;
    }
        figure{
            position: relative;
        }
        figcaption{
            box-sizing:border-box;
            background: rgba(255,255,255,.6);
            max-width: 50%;
            min-height: 200px;
            padding-top:20px;
            padding-left: 20px;
            position: absolute;
            bottom: 0;
            left: 0;
            .kreon-title{
                display: block;
            }
        }
        .introslide{
            max-width: 80%;
            float: left;
            font-size: .8rem;
            padding-top: 20px;
            padding-right: 10px;
        }
        .ensavoirplus{
                        max-width:20%;
            float: left;
         margin-top: 10px;
         a{display: block;}
        }
        .cck_module_list>div{
            position: relative;
        }
    }
   
} */
/* --------------------------------------
   SLIDER
-------------------------------------- */
.slick-arrow {
  color: white;
  cursor: pointer;
  position: absolute;
  right: 0px;
  background: rgba(255, 255, 255, 0.6);
  top: 0;
  z-index: 1;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 40px;
}

.slick-arrow:hover {
  background: #F09100;
}

.slick-arrow.prev {
  right: 80px;
}

.slick-arrow.next {
  right: 40px;
}

.slick-dots {
  position: absolute;
  bottom: 10px;
  left: 0;
}

.slick-dots li {
  margin-left: 20px;
  display: inline-block;
  background: lightgrey;
  border-radius: 500px;
  height: 9px;
  width: 9px;
}

.slick-dots li.slick-active {
  background: #9B0F05;
}

.slick-dots li button {
  background: transparent;
  border: none;
  font-size: 0;
}

.slick .slick-track figure {
  position: relative;
}

.slick .slick-track figcaption {
  overflow: hidden;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.6);
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 2% 2% 30px 2%;
}

@media screen and (min-width: 970px) {
  .slick .slick-track figcaption {
    width: 50%;
    width: 50%;
    min-height: 200px;
    min-width: 50%;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 0;
    padding-bottom: 0;
  }
}

.slick .slick-track a.kreon-title {
  color: #9B0F05;
  font-size: 5vw;
  line-height: 5vw;
}

@media screen and (min-width: 970px) {
  .slick .slick-track a.kreon-title {
    font-size: 3rem;
    line-height: 2.9rem;
  }
}

.slick .slick-track a.kreon-title:hover {
  text-decoration: underline;
}

.slick .slick-track .introslide {
  display: none;
  font-size: .8rem;
  padding: 10px 20px  10px 0;
}

@media screen and (min-width: 500px) {
  .slick .slick-track .introslide {
    width: 75%;
    float: left;
    display: block;
  }
}

.slick .slick-track .ensavoirplus {
  text-align: right;
  display: none;
  margin-top: 10px;
}

@media screen and (min-width: 500px) {
  .slick .slick-track .ensavoirplus {
    width: 25%;
    float: right;
    margin-right: 0;
    display: block;
  }
}

.slick .slick-track .cck_module_list > div {
  position: relative;
}

.slick .toutarticles {
  position: absolute;
  right: 30px;
  bottom: 20px;
  background: #9B0F05;
  display: inline-block;
  text-align: center;
  max-width: 130px;
  padding: 12px 25px;
  border-radius: 7px;
  color: white;
}

.slick .toutarticles .cadomus-ico-livre {
  display: block;
  font-size: 4rem;
}

.slick .toutarticles .kreon-txt {
  display: block;
  font-size: .3rem;
}

.slick .toutarticles:hover {
  background: #5A5A55;
}

.twentytwenty-horizontal .twentytwenty-handle:before, .twentytwenty-horizontal .twentytwenty-handle:after, .twentytwenty-vertical .twentytwenty-handle:before, .twentytwenty-vertical .twentytwenty-handle:after {
  content: " ";
  display: block;
  background: white;
  position: absolute;
  z-index: 30;
  -webkit-box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
  -moz-box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
  box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
}

.twentytwenty-horizontal .twentytwenty-handle:before, .twentytwenty-horizontal .twentytwenty-handle:after {
  width: 3px;
  height: 9999px;
  left: 50%;
  margin-left: -1.5px;
}

.twentytwenty-vertical .twentytwenty-handle:before, .twentytwenty-vertical .twentytwenty-handle:after {
  width: 9999px;
  height: 3px;
  top: 50%;
  margin-top: -1.5px;
}

.twentytwenty-before-label, .twentytwenty-after-label, .twentytwenty-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.twentytwenty-before-label, .twentytwenty-after-label, .twentytwenty-overlay {
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.twentytwenty-before-label, .twentytwenty-after-label {
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  transition-property: opacity;
}

.twentytwenty-before-label:before, .twentytwenty-after-label:before {
  color: white;
  font-size: 13px;
  letter-spacing: 0.1em;
}

.twentytwenty-before-label:before, .twentytwenty-after-label:before {
  position: absolute;
  background: rgba(255, 255, 255, 0.2);
  line-height: 38px;
  padding: 0 20px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.twentytwenty-horizontal .twentytwenty-before-label:before, .twentytwenty-horizontal .twentytwenty-after-label:before {
  top: 50%;
  margin-top: -19px;
}

.twentytwenty-vertical .twentytwenty-before-label:before, .twentytwenty-vertical .twentytwenty-after-label:before {
  left: 50%;
  margin-left: -45px;
  text-align: center;
  width: 90px;
}

.twentytwenty-left-arrow, .twentytwenty-right-arrow, .twentytwenty-up-arrow, .twentytwenty-down-arrow {
  width: 0;
  height: 0;
  border: 6px inset transparent;
  position: absolute;
}

.twentytwenty-left-arrow, .twentytwenty-right-arrow {
  top: 50%;
  margin-top: -6px;
}

.twentytwenty-up-arrow, .twentytwenty-down-arrow {
  left: 50%;
  margin-left: -6px;
}

.twentytwenty-container {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  z-index: 0;
  overflow: hidden;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
}

.twentytwenty-container img {
  max-width: 100%;
  position: absolute;
  top: 0;
  display: block;
}

.twentytwenty-container.active .twentytwenty-overlay, .twentytwenty-container.active :hover.twentytwenty-overlay {
  background: transparent;
}

.twentytwenty-container.active .twentytwenty-overlay .twentytwenty-before-label,
.twentytwenty-container.active .twentytwenty-overlay .twentytwenty-after-label, .twentytwenty-container.active :hover.twentytwenty-overlay .twentytwenty-before-label,
.twentytwenty-container.active :hover.twentytwenty-overlay .twentytwenty-after-label {
  opacity: 0;
}

.twentytwenty-container * {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.twentytwenty-before-label {
  opacity: 0;
}

.twentytwenty-before-label:before {
  content: "Before";
}

.twentytwenty-after-label {
  opacity: 0;
}

.twentytwenty-after-label:before {
  content: "After";
}

.twentytwenty-horizontal .twentytwenty-before-label:before {
  left: 10px;
}

.twentytwenty-horizontal .twentytwenty-after-label:before {
  right: 10px;
}

.twentytwenty-vertical .twentytwenty-before-label:before {
  top: 10px;
}

.twentytwenty-vertical .twentytwenty-after-label:before {
  bottom: 10px;
}

.twentytwenty-overlay {
  -webkit-transition-property: background;
  -moz-transition-property: background;
  transition-property: background;
  background: transparent;
  z-index: 25;
}

.twentytwenty-overlay:hover {
  background: rgba(0, 0, 0, 0.5);
}

.twentytwenty-overlay:hover .twentytwenty-after-label {
  opacity: 1;
}

.twentytwenty-overlay:hover .twentytwenty-before-label {
  opacity: 1;
}

.twentytwenty-before {
  z-index: 20;
}

.twentytwenty-after {
  z-index: 10;
}

.twentytwenty-handle {
  height: 38px;
  width: 38px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -22px;
  margin-top: -22px;
  border: 3px solid white;
  -webkit-border-radius: 1000px;
  -moz-border-radius: 1000px;
  border-radius: 1000px;
  -webkit-box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
  -moz-box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
  box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
  z-index: 40;
  cursor: pointer;
}

.twentytwenty-horizontal .twentytwenty-handle:before {
  bottom: 50%;
  margin-bottom: 22px;
  -webkit-box-shadow: 0 3px 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5);
  -moz-box-shadow: 0 3px 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5);
  box-shadow: 0 3px 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5);
}

.twentytwenty-horizontal .twentytwenty-handle:after {
  top: 50%;
  margin-top: 22px;
  -webkit-box-shadow: 0 -3px 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5);
  -moz-box-shadow: 0 -3px 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5);
  box-shadow: 0 -3px 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5);
}

.twentytwenty-vertical .twentytwenty-handle:before {
  left: 50%;
  margin-left: 22px;
  -webkit-box-shadow: 3px 0 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5);
  -moz-box-shadow: 3px 0 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5);
  box-shadow: 3px 0 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5);
}

.twentytwenty-vertical .twentytwenty-handle:after {
  right: 50%;
  margin-right: 22px;
  -webkit-box-shadow: -3px 0 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5);
  -moz-box-shadow: -3px 0 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5);
  box-shadow: -3px 0 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5);
}

.twentytwenty-left-arrow {
  border-right: 6px solid white;
  left: 50%;
  margin-left: -17px;
}

.twentytwenty-right-arrow {
  border-left: 6px solid white;
  right: 50%;
  margin-right: -17px;
}

.twentytwenty-up-arrow {
  border-bottom: 6px solid white;
  top: 50%;
  margin-top: -17px;
}

.twentytwenty-down-arrow {
  border-top: 6px solid white;
  bottom: 50%;
  margin-bottom: -17px;
}

/* ----------------------------------------------
   CUSTOM CADOMUS            
---------------------------------------------- */
.tags {
  padding: 15px 0 7px 0;
}

.tags > ul li {
  display: inline-block;
}

.tags > ul li a {
  text-transform: uppercase;
  color: #F09100;
}

.tags > ul li a:after {
  content: ', ';
}

.tags > ul li:last-of-type > a:after {
  content: "";
}

.date {
  color: #F09100;
  font-size: .8rem;
}

#cck3r_label_cadomus_content {
  display: none;
}

#cck3r_cadomus_content {
  float: none;
  display: inline-block;
}

body div.cck_forms .inputbox {
  border-radius: 0;
  border: none;
}

/* -------------------------------------------
   HOME SLIDER            
------------------------------------------- */
.kreon-title {
  color: #9B0F05;
  font-size: 3rem;
  line-height: 2.9rem;
}

.kreon-title:hover {
  text-decoration: underline;
}

#cck1r_label_cadomus_content {
  display: none;
}

/* .recherche-seblod{
	margin: 30px 0;
	h3{color:black;}
	.cck_form.cck_form_button_submit{
		background: $first;
		display: inline-block;
		padding: 2px;
		margin-top: 10px;

	}
	button{border:2px solid white; color:white;}
	input[type='text']{background: $first; color:white;}
	select{background: $first; color:white; border:none; display: block;width: 100%;max-width: 270px;}
	input,select{
		margin: 7px 0;
	}
	label{display: none;}
}

.cck_page_items,.cck_module_list{

	.btn-presentation{
		padding: 1%;
		background: lighten($sixth,12%);;
		color: white;
		
		display: inline-block;
		cursor: pointer;
	}
	.presentation-wrapper{
		border: 2px solid lighten($sixth, 2%);
		border-left: 8px solid $sixth;
		padding: 2%;
		margin-bottom: 2%;
	
	}
	.client{
		background: lighten(lightgrey,2%);
		margin-bottom: 2%;
		 [class^="pixico"], [class*=" pixico"] {
		 	margin-right: 10px;
		 }
		.colrow{
			@include cf;
			.col1 {@include span(1 of 2);
				padding-left: 1%;
				padding-top: 10px;
				padding-bottom: 10px;}
			.col2 {
				padding-top: 10px;
				padding-bottom: 10px;
				@include span(1 of 2 last);
				border-left: 2px dotted $sixth;

			}
		}


		.client-wrapper{
			.grp-phone{}	
			.grp-internet{
			}
			>h2{
		padding: 2%;
				text-align: center;
				background: lighten($sixth, 2%);
				 color:white;
								}		
		}
	}




}
.cck_page>div>h2{

}
 */
.list-categorie > div {
  border-bottom: #F09100 solid 1px;
}

body [class^='.debug'], body .debug, body .debug-badge {
  display: none;
}

.associate-tag a {
  margin-left: 4px;
}

.associate-tag {
  color: darkgrey;
}
