// -----------------------------------------------------------------------------
// MENU PRINCIPAL
// -----------------------------------------------------------------------------


li.contactmail{
    a{
        background: url(images/mail.png) no-repeat;
        font-size: 0 !important;
        height: 15px;width: 100%;
        display: block;
        &:hover{
            background-position: bottom left;
        }
    }
} 
li.orange>a{cursor:pointer !important;}

.menu-wrapper {
    .menu-principal-container { 
            display: none;
            @media screen and (min-width:$containermedium) { display: block; }
        .menu ul {
            display: none;
        }
    }
    .menu {
        background: $first;
        @include cf;
        height: 43px;
        li {
            a,
            span {
                display: block;
                padding: 10px;
                text-align: center;
                text-transform: uppercase;
                color:$second;

            }
        }
        >li {
            z-index: 100000000000000000;
            position: relative;

            &:hover {
                
                background: darken($first, 2%);
            }
            >a{
                font-family: 'Kreon', serif;
            }
            >ul {
                z-index: 100;
                position: absolute;
                right: 0;
                left: 0;
                background: $sixth;
                top: 42px;
                >li {
                    a,
                    span {
                        color: white;
                        font-family: 'Open Sans', sans-serif;
                        font-size: .7rem;
                        text-transform: none;
                    }
                    &:hover {
                        background: lighten($sixth, 10%);
                    }

                }
            }
        }
    }
}
// -----------------------------------------------------------------------------
// SOUS-MENU
// -----------------------------------------------------------------------------
.sub-menu .sub-menu{
      background: lighten($sixth, 10%);
    margin: 0 ;
    padding: 0 !important;
    li{
        padding:0 !important;
    }
}

.submenu {    
    display: block;
    background: $sixth;
    .menu-principal-container {

        .menu {
            >li>a {
                display: none;
            }
            ul {
                padding: 10px 0;
                display: block;
                &:hover{
                    background: darken($sixth,5%);
                }

                >li{
                    display: block;
                    a{
                        display: block;
                        padding: 5px;
                        font-size: .8rem;
                        color:white;
                        &.current.active,
                        &:hover {
                            background: $first;
                        }
                    }
                }
            }

        }
    }

}
// -----------------------------------------------------------------------------
// MENU MOBILE
// -----------------------------------------------------------------------------
.toggle-menu {
    cursor:pointer;
    position: fixed;
    top: 20px;
    left: 10px;
    text-align: center;
    background:white;
    z-index: 1000001;
    border: 1px solid $first;
    span{
        display: inline-block;
        padding: 3px 10px;
        font-size: 2.5rem;
        color:$first;
    }
    &.mintop{
            @media screen and (min-width:$containermedium) {top: 0;left: 0;}
        
    }
}

.menumobile {
    overflow-y: auto;

    display: none;
    -moz-box-shadow: 1px 0px 1px 0px lightgrey;
    -webkit-box-shadow: 1px 0px 1px 0px lightgrey;
    -o-box-shadow: 1px 0px 1px 0px lightgrey;
    box-shadow: 1px 0px 1px 0px lightgrey;
    filter:progid:DXImageTransform.Microsoft.Shadow(color=#656565, Direction=90, Strength=1);
    z-index: 1000000;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    text-align: center;
    background: white;
    padding-top: 100px;
    min-width: 250px;

    ul.menu {

        li {
            a,
            span {
                display: block;
                padding: 5px 10px;
                text-align: center;
                text-transform: uppercase;
                color:$first;
                font-family: 'Kreon', serif;
                text-decoration: none;
                letter-spacing: 1px;
                text-align: left;
                &:hover{
                    background: $first;
                    color:white;
                }
            }
            >ul{

                li{
                    a,
                    span {
                        &:hover{
                            background: $first;
                            color:white;
                        }
                        font-family: 'Open Sans', sans-serif; 
                        font-size: .9rem;
                        letter-spacing: 0;
                        text-align: left;
                        display: block;
                        padding: 1px 10px;
                        text-transform: none;
                        color:$second;
                    }
                }
                
            }
        }
    }

}
// -----------------------------------------------------------------------------
// FOOTER MENU
// -----------------------------------------------------------------------------
.bottom {
    .menu {
        span {
            font-size: .8rem;
            font-weight: 600;
            margin: 10px 0 5px 0;
            display: block;
            color: $second;
            text-transform: uppercase;
        }
        a {
            color: white;
            font-size: .7rem;
        }
    }
}

div[class^="footer"]{
    padding: 0px 2% 20px 2% ;

    .menu-item-has-children>a,.orange>a,a[href="#"],.menu>li:first-of-type >a{

        display: inline-block;
        padding: 30px 0 5px 0;
        cursor:default;
        color:$second;
        font-weight: 700;
        text-transform: uppercase;
    }
    a{
        font-size: .8rem;
        &:hover{
            color:$second;
        }
    }
}


/* --------------------------------------
  > tablette
-------------------------------------- */

@media screen and (min-width:$containermedium) {
    header .menu-wrapper .menu {
            >li {
                @include with-layout($nogutter) {
                    @include span(1 of 6);
                    min-height: 1px;
                }
                border-bottom: 7px transparent solid;
                &.current.active,
                &:hover {
                    border-color: $second;
                }
            }
        }
        header .submenu .menu {
            @include cf;
            >li {
                text-align: center;
    
                @include with-layout($nogutter) {
                    @include span(1 of 6);
                    min-height: 1px;
    
            }
        }
    }
}