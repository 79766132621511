.associate-tag{
	a{
		color:$second;
		text-transform: uppercase;	
		font-size: .8rem;
	}
}
.date{margin-bottom: 10px;}
.content-single{
	padding-top: 20px;
	padding-bottom: 20px;
	text-align: justify;
}
ul.galerie{
	@include cf;
	>li{
		list-style-type: none;
		@media screen and (min-width:$containersmall) and(max-width: $containermedium) {@include span(2	of 4);}
		@media screen and (min-width:$containermedium) {@include span(1	of 3); }
	}
}


/* ------------------------------------------
   JQUERY PAN -> CARTOGRAPHIE
------------------------------------------ */	
/* .pan{

	@media screen and (min-width:$containersmall) { display: block;}
	cursor:pointer;
	position: relative;
	overflow: hidden;

	&:after{
		@include transition(right .5s ease);
	
		content: "\e90d";
		font-family: 'cadomus' !important;
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;
		font-size: 2rem;
		color: white;
		position: absolute;
		bottom: 3px;
		right: -600px;

		background: rgba(0, 0, 0, 0.5);
		padding: 40px;
		border-top-left-radius:25px ;		
		display: block;


	}
	&:hover:after{
		display: block;
		right: 3px;
		
	}

}
.contentarea .content .panmobile{
	display: none;	
		@media screen and (min-width:$containersmall) { display: none;	 }
		&.zoom{
			img{width: 600%;}	


		}
		overflow:hidden;	
	img{
		max-width: 600% !important;	
		width: 100%;


	}

}
.panWrapper {
	position:fixed; width:100%; height:100%; overflow:hidden; background-color:rgba(0,0,0,0.9); z-index:9999; left:0; top:0;bottom: 0;	right: 0;	 display:none; cursor:move;
	&:after{
		content:""; display: block;	
		position:fixed;right:3vw;bottom:3vw;height: 6vw;width: 6vw; 
		background-position:center center;   background-size: contain; background-repeat:no-repeat; background-image:url(images/watermark.png);
	}
}
.panWrapper * {-moz-user-select: -moz-none;-khtml-user-select: none;-webkit-user-select: none;-o-user-select: none;user-select: none;}
body .panWrapper img{max-width: 600% !important;}
.panWrapper a.controls {background-size:100%; width:32px; height:32px; display:block; position:fixed; left:50%; z-index:2; bottom:30px; cursor:pointer;}
.panWrapper a.controls.in{background-image:url('images/zoomIn.png'); margin-left:-30px;}
.panWrapper a.controls.out{background-image:url('images/zoomOut.png'); margin-left:30px;}
.panWrapper a.controls.close{background-image:url('images/close.png'); left:auto; right:30px; top:30px;}
.panWrapper .loading{z-index:3; position:absolute; left:50%; top:50%; margin:-50px 0 0 -50px; width:100px; height:100px; background-image:url(images/loading.gif); background-repeat:no-repeat; background-size:100%; display:block;}	

 */

div.carte{
	height: 500px !important;
	overflow: hidden;
	.smooth_zoom_preloader{height: 500px !important;	}
}


 /* ------------------------------------------
   ARTICLES
------------------------------------------ */
ul.galerie{
	li{
		padding: 10px;	
	}
}
ul.galerie{
	a{
		position: relative;	
		display: block;	
		>span{
			position: absolute;	
			top: 10px;	right: 0;	
			background: $first;
			padding: 2px 2%;	
			font-weight: bold;	
			display: inline-block;	
			color:white;
			font-size: .7rem
		}
		&:after{
			content: "\e90d";
			font-family: 'cadomus' !important;
			speak: none;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			text-transform: none;
			line-height: 1;
			font-size: 2rem;
			color:white;
			position: absolute;	
			bottom: 3px;	
			right: 3px;	
			background: rgba(0,0,0,.5);
			padding: 10px;	
			border-radius: 100px;	
			display: none;	
		}
		&:hover{
			>span{background: $second;}
			&:after{display: block;	}
		}
	}
	.galerie-thumb{
		display: block;	height: 188px;	
		background-position: center;
		background-color: $third;
		min-width: 150px;
		min-height: 150px;
		background-size: contain;
		background-repeat: no-repeat;
		&.video{
			background-color:black;
		}
	}

}	

/* ------------------------------------------
   Vidéo
------------------------------------------ */

.iframe-responsive-wrapper        {
    position: relative;
}

.iframe-responsive-wrapper .iframe-ratio {
    display: block;
    width: 100%;
    height: auto;
}

.iframe-responsive-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.twitterwidget iframe{width: 100%;	} 
.video-description{
	padding-top: 20px;
	padding-bottom: 20px;
}
/* ------------------------------------------
   LOGO PARTENAIRES
------------------------------------------ */
.logo-partenaire{
	max-width: 170px;
	width: 15%;
	display: inline-block;
	height: 90px;
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
}
.grp-partenaires{
	
	padding: 10px;
	text-align: center;
}

/* ------------------------------------------
   ARTICLES
------------------------------------------ */

.articles-with-intro{
	.content-with-social{
		.content-social{
			top: 0;
			.btn-partage{	padding: 10px 0;		}
			@media screen and (min-width:$containermedium) { 
				@include span(2 of 12);
				padding-right: 10px;
				.btn-partage{float: none;	padding:  0;		}	
			 }
				 
		}
		.content-info{
				@media screen and (min-width:$containermedium) { 
					@include span(10 of 12);
				 }
		}
	}
}
.btn-partage a{
	color: $sixth;
	font-size: 1.3rem;
	&:hover{
		color:$second;
	}
}
.content-social{
	position: relative;top: -20px;
	.btn-partage{display: inline-block; float: right;		}
	 strong{

	 	display: inline-block;	
	 	padding: 8px 0;
	 	font-weight: normal;
	 	color:$second;
	 }
	hr{
		border-bottom: none;
	}
}

#page-404{
	position: fixed;
	top: 0;right: 0;bottom: 0;left: 0;
}
.message-404{
	position: absolute;
	right: 0;left: 0;bottom: 0;top: 0;	
	background: url(images/einstein.jpg) center center no-repeat #2E2E2E;
	background-size: contain;
	.right404{
		min-width: 350px;
		margin-top: 50vh; /* poussé de la moitié de hauteur de viewport */
		transform: translateY(-50%); /* tiré de la moitié de sa propre hauteur */
		width: 50%;
		float: right;
	}
	h1,h2,h3{color:white;padding: 2vw;text-align: center;}
}


.wp-caption-text{
	text-align: center;	color:$second;
	font-size: .8rem;
}


// -----------------------------------------------------------------------------
// Plan du site
// -----------------------------------------------------------------------------

div.plan-du-site{
	@include cf;
	>div{
			@media screen and (min-width:$containermedium) {@include span(1 of 3);}
		
	}
}

// -----------------------------------------------------------------------------
// CONTACT
// -----------------------------------------------------------------------------
form {
	input[type="text"],input[type="email"],textarea{
		max-width: 90%;
	
	}
}

div.block-soutenez{
	hr{color:white;border-color: white;margin: 10px;}
	text-align: justify;
	border-radius: 5px;
	margin: 30px;
	padding: 40px;
	background: lighten($second, 40%);
	@include cf;
	.orange{
		color:$second;

	}
	.rouge{
		color:$first;

	}
	.titre-soutenez{
		&:before{
			content:"";
			height: 150px;
			width: 150px;
			background-color: $first;
			display: block;
			float: left;
			margin-right: 30px;
			background-image: url(images/logotransparent.png);
			background-repeat: no-repeat;
			background-position: top center;
			background-size: 270px 200px;
		}
		font-size: 3rem;

	}
	.left-soutenez{
		padding: 15px;
		@media screen and (min-width:$containermedium) {
			float: left;
			width: 30%;
		}
	}
	.right-soutenez{
		padding: 15px;
		@media screen and (min-width:$containermedium) {
			float: right;
			width: 70%;
		}
		color:$first;

	}
	.soutenez-item{

		padding: 30px  30px 30px 100px;
		margin: 10px;
		background: lighten($second, 20%);
		position: relative;
		&:before{
			   font-family: 'cadomus' !important;
			   speak: none;
			   font-style: normal;
			   font-weight: normal;
			   font-variant: normal;
			   text-transform: none;
			   line-height: 1;
			content: "\e315";
			display: block;
			float: left;
			color:white;
			width: 50px;
			font-size: 4rem;
			position: absolute;
			top: 0;bottom: 0;left: 0;
			padding-top: 20px;
			   /* Better Font Rendering =========== */
			   -webkit-font-smoothing: antialiased;
			   -moz-osx-font-smoothing: grayscale;
		}

	}
}

	.smooth_zoom_preloader {
		background-image: url(images/preloader.gif)!important ;
	}	
	.smooth_zoom_icons {
		background-image: url(images/icons.png)!important ;
	}
.carte{
	position: relative;	
}

.associate-tag a{
	margin-left: 4px;	
}