/*
Theme Name: Cadomus
Theme URI: http://www.pixelea.fr/
Description: Thème Cadomus, Pixelea
Version: 1.0
Author: GEHIN Nicolas
 
Creation Theme by GEHIN Nicolas - Pixelea || http://www.pixelea.fr
 
*/
// ======
@import "susy";
@import "mixin";
@import "reset"; 
@import "variable";
@import "font";
@import "position";
@import "images";
@import "menu";
@import "module"; 
@import "categorie"; 
@import "single"; 
@import "sidebar";
@import "loader";
@import "anime";
@import "fancy";
@import "donation";
/* @import "galerie"; */
@import "slick";
@import "twentytwenty";
@import "seblod";

.list-categorie{
	>div{border-bottom: $second solid 1px;}
}
body{
	[class^='.debug'],.debug,.debug-badge{display: none;}
}
.associate-tag a{
	margin-left: 4px;	
}
.associate-tag{color:darkgrey;}