 img{max-width: 100%;}
 .post_content.cat-9{
 	 img{max-width: none!important;}
 }
 .contentarea:not(.cat-39) .content{
	img{
		width: 600%;	
	
height: auto !important; 


		display: inline-block;
		&.aligncenter{
			margin: 0 auto;
			display: block;
		}
		&.alignright{
		float: right;
		margin-left: 10px;
			&:after{
				content:"";
				clear:both;
				display: block;
			}
		}
		&.alignleft{
		float: left;
		margin-right: 10px;
			&:after{
				content:"";
				clear:both;
				display: block;
			}
		}
	}

} 
 .right img{max-width: 100%!important;	height: auto !important;
	display: inline-block;	}
.contentarea.list .wrapper{
	img{
		width: 150px !important;

	}
}
.smooth_zoom_preloader{
	overflow: hidden;
}