/* ----------------------------------------------
   CUSTOM CADOMUS            
---------------------------------------------- */

.tags{
	padding: 15px 0 7px 0;
	>ul{
		li{display: inline-block; 
			a{
				text-transform: uppercase;
				color:$second;
					&:after{
						content:', ';
					}
	
			}
		}
		li:last-of-type>a:after{content:"";}
	}
}
.date{ color:$second; font-size: .8rem;}

#cck3r_label_cadomus_content{
	display: none;

}
#cck3r_cadomus_content{
	float: none;
	display: inline-block;
}
body div.cck_forms .inputbox{border-radius: 0; border:none;}



/* -------------------------------------------
   HOME SLIDER            
------------------------------------------- */
@include with-layout($nogutter){

	.kreon-title{
		color:$first;
		font-size: 3rem;
		line-height: 2.9rem;
		&:hover{
			text-decoration: underline;
		}
	}
	
	

	
}





#cck1r_label_cadomus_content{display: none;}






/* .recherche-seblod{
	margin: 30px 0;
	h3{color:black;}
	.cck_form.cck_form_button_submit{
		background: $first;
		display: inline-block;
		padding: 2px;
		margin-top: 10px;

	}
	button{border:2px solid white; color:white;}
	input[type='text']{background: $first; color:white;}
	select{background: $first; color:white; border:none; display: block;width: 100%;max-width: 270px;}
	input,select{
		margin: 7px 0;
	}
	label{display: none;}
}

.cck_page_items,.cck_module_list{

	.btn-presentation{
		padding: 1%;
		background: lighten($sixth,12%);;
		color: white;
		
		display: inline-block;
		cursor: pointer;
	}
	.presentation-wrapper{
		border: 2px solid lighten($sixth, 2%);
		border-left: 8px solid $sixth;
		padding: 2%;
		margin-bottom: 2%;
	
	}
	.client{
		background: lighten(lightgrey,2%);
		margin-bottom: 2%;
		 [class^="pixico"], [class*=" pixico"] {
		 	margin-right: 10px;
		 }
		.colrow{
			@include cf;
			.col1 {@include span(1 of 2);
				padding-left: 1%;
				padding-top: 10px;
				padding-bottom: 10px;}
			.col2 {
				padding-top: 10px;
				padding-bottom: 10px;
				@include span(1 of 2 last);
				border-left: 2px dotted $sixth;

			}
		}


		.client-wrapper{
			.grp-phone{}	
			.grp-internet{
			}
			>h2{
		padding: 2%;
				text-align: center;
				background: lighten($sixth, 2%);
				 color:white;
								}		
		}
	}




}
.cck_page>div>h2{

}
 */

