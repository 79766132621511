.migla-panel-body.form-horizontal{
	.form-group.mg_giving-levels>div:first-of-type label.mg_control-label{
		display: none;
	}
}
#migla_donation_form{
	>.migla-panel:nth-of-type(2){
		.migla-panel-body.form-horizontal {
			padding-bottom: 0;
		}
		.migla-panel-body.form-horizontal .col-sm-3{
			display: none;
		}
		.migla-panel-body.form-horizontal .col-sm-6{
			width: auto;
		}
		&:after{
			content:"Les membres de l'association sont tous bénévoles. La totalité des dons est utilisée pour la réalisation du projet. Aussi, afin de limiter les frais de gestion pour l'association, les reçus fiscaux ne sont pas délivrés pour des versements inférieurs à 15 euros.";
		display: block;
		padding: 0 20px 20px 20px;
		text-align: justify;
		font-style: italic;
		}
	}
	.migla_amount_lbl>div{
		font-size: 0;
	}

}

	body header.migla-panel-heading{
	position: static !important;
}

// DONATION FORM
// --------------------------------------------------

    #dgx-donate-container > div{
    	//#_dgx_donate_add_to_mailing_list{display: none;}
    	span[id ^='dgx_donate_giving_level'],#other_radio_button{
    		padding: 10px;
    		input{
    			margin-right: 5px;
    		}

    	}
    	&#dgx-donate-form-donation-section, &#dgx-donate-form-donor-section, &#dgx-donate-form-billing-section{
    		background-color: #fafafa;
    		border: 1px solid #eeeeee;
    		padding: 0 20px 20px 20px;
    		margin-top: 20px;
    		margin-bottom: 20px;
    		>div{
    			min-height: 50px;
    			clear:both;
    			input, select{
    				    border: 1px solid #ddd;

    				border-radius: 5px;
    			 }
    		}
    	}

    }
#_dgx_donate_donor_first_name input{
	margin-left: 30px;
}
#_dgx_donate_donor_last_name input{
	margin-left: 55px;
}
#_dgx_donate_honoree_name input{
	margin-left: 96px;
}
#_dgx_donate_donor_email input{
	margin-left: 33px;
}
#_dgx_donate_donor_phone input{
	margin-left: 14px;
}
#_dgx_donate_donor_address input{
	margin-left: 42px;
}
#_dgx_donate_donor_address2 input{
	margin-left: 28px;
}
#_dgx_donate_donor_city input{
	margin-left: 70px;
}
#_dgx_donate_donor_state input{
	margin-left: 10px;
}
#_dgx_donate_donor_province input{
	margin-left: 70px;
}
#_dgx_donate_donor_country select{
	margin-left: 65px;
}
#_dgx_donate_donor_zip input{
	margin-left: 10px;
}
#dgx-donate-pay-enabled input[type="image"]{border:none; max-width: 200px;}