@import url(https://fonts.googleapis.com/css?family=Kreon:700,400|Open+Sans:400,700,400italic);


body {
    font-family: 'Open Sans', sans-serif;
}

h1,
h2,
h3,
h4,
.nav a,
.slogan,
.footer5 strong,
[class^="kreon-"]
{
    font-family: 'Kreon', serif;
    text-decoration: none;
    letter-spacing: 1px;
    text-align: left;
}
.bottom .nav a,
.right h1,
.partners h3,
[class^="opensans"]{
    font-family: 'Open Sans', sans-serif; 
}
p {
    text-align: justify;
    margin: 10px 0;
}
.excerpt{
    font-size: .9rem;
}
.content-info{
    text-align: justify;

    -webkit-hyphens: auto;
       -moz-hyphens: auto;
            hyphens: auto;
}

.slogan,
.footer5{
    color:white;
    strong{color:$second;}
}
.nav a{color:$second;}

.titre>h1{font-size: 3rem; color:$first;}

hr{
    border: 1px solid $second;

}
h1,h2,h3,h4,h5{
    color:$first;
}
h3.soustitre{
     font-family: 'Open Sans', sans-serif; 
     margin: 0;
     padding: 0;    
     color:$second; 
     font-size: .8rem;
     margin-top: -13px; 
 
}

@font-face {
    font-family: 'cadomus';
    src:    url('fonts/cadomus.eot?8mwmgp');
    src:    url('fonts/cadomus.eot?8mwmgp#iefix') format('embedded-opentype'),
        url('fonts/cadomus.ttf?8mwmgp') format('truetype'),
        url('fonts/cadomus.woff?8mwmgp') format('woff'),
        url('fonts/cadomus.svg?8mwmgp#cadomus') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="cadomus-ico-"], [class*=" cadomus-ico-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'cadomus' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.cadomus-ico-twitter:before {
    content: "\e900";
}
.cadomus-ico-googleplus:before {
    content: "\e90e";
}
.cadomus-ico-caen:before {
    content: "\e901";
}
.cadomus-ico-contrat:before {
    content: "\e902";
}
.cadomus-ico-cube:before {
    content: "\e903";
}
.cadomus-ico-dialog:before {
    content: "\e904";
}
.cadomus-ico-facebook:before {
    content: "\e905";
}
.cadomus-ico-fond:before {
    content: "\e906";
}
.cadomus-ico-livre:before {
    content: "\e907";
}
.cadomus-ico-loader:before {
    content: "\e908";
}
.cadomus-ico-map:before {
    content: "\e909";
}
.cadomus-ico-photo:before {
    content: "\e90a";
}
.cadomus-ico-presse:before {
    content: "\e90b";
}
.cadomus-ico-question:before {
    content: "\e90c";
}
.cadomus-ico-search:before {
    content: "\e90d";
}
.cadomus-ico-arrow-left:before {
    content: "\e90f";
}
.cadomus-ico-arrow-left-alt1:before {
    content: "\e910";
}
.cadomus-ico-arrow-right:before {
    content: "\e911";
}
.cadomus-ico-arrow-right-alt1:before {
    content: "\e912";
}
.cadomus-ico-chevron-left2:before {
    content: "\f0a4";
}
.cadomus-ico-chevron-right2:before {
    content: "\f078";
}
.cadomus-ico-device-camera:before {
    content: "\f056";
}
.cadomus-ico-device-camera-video:before {
    content: "\f057";
}
.cadomus-ico-device-desktop:before {
    content: "\f27c";
}
.cadomus-ico-device-mobile:before {
    content: "\f038";
}
.cadomus-ico-three-bars:before {
    content: "\f05e";
}
.cadomus-ico-triangle-left:before {
    content: "\f044";
}
.cadomus-ico-triangle-right:before {
    content: "\f05a";
}
.cadomus-ico-x:before {
    content: "\f081";
}
.cadomus-ico-step-backward:before {
    content: "\f048";
}
.cadomus-ico-chevron-left:before {
    content: "\f053";
}
.cadomus-ico-chevron-right:before {
    content: "\f054";
}
.cadomus-ico-minus:before {
    content: "\f068";
}
.cadomus-ico-skip_next:before {
    content: "\e044";
}
.cadomus-ico-skip_previous:before {
    content: "\e045";
}
.cadomus-ico-call:before {
    content: "\e0b0";
}
.cadomus-ico-keyboard_arrow_left:before {
    content: "\e314";
}
.cadomus-ico-keyboard_arrow_right:before {
    content: "\e315";
}
.cadomus-ico-phone_android:before {
    content: "\e324";
}
.cadomus-ico-smartphone:before {
    content: "\e32c";
}
.cadomus-ico-dehaze:before {
    content: "\e3c7";
}
.cadomus-ico-menu:before {
    content: "\e5d2";
}
